import React from 'react'
import pluralize from 'pluralize'
import { ReviewCyclesInterface, ReviewStageStats } from '@src/interfaces/reviewCycles'
import { useGetReviewStageStats } from '@src/api/reviewCycles'
import {
  makeDescriptionText,
  Progress,
} from '@src/features/ReviewCycle/PerformanceReviewCycle/components/Progress/Progress'
import {
  ReviewsDetail,
  ReviewsDetails,
} from '@src/features/ReviewCycle/PerformanceReviewCycle/CycleStages/Timeline/StageWidget/Details/ReviewsStats/ReviewsDetails'
import { PortionLabel } from '@src/features/ReviewCycle/PerformanceReviewCycle/components/Progress/PortionLabel'
import { PerformanceSettingsInterface } from '@src/interfaces/settings'
import { settingsModel } from '@src/features/ReviewCycle/PerformanceReviewCycle/models/SettingsModel'

const toReviewsDetails = (
  stats: ReviewStageStats,
  settings?: PerformanceSettingsInterface,
): ReviewsDetail[] => {
  const lmDetails = { name: 'LM completion', value: stats.lm_completion / 100 }
  const fmDetails = { name: 'FM completion', value: stats.fm_completion / 100 }
  const selfDetails = {
    name: 'Self completion',
    value: stats.self_completion / 100,
  }

  return settings && settingsModel.hasFunctionalManagement(settings)
    ? [lmDetails, fmDetails, selfDetails]
    : [lmDetails, selfDetails]
}

const makePortionText = (name: string, dividend: number, divisor: number): string =>
  `${pluralize(name, divisor)} ${pluralize('have', dividend)} received reviews`
const makeEmptyPortionText = (name: string): string =>
  `There are no ${pluralize(name)} generated for this cycle`

interface Props {
  cycle: ReviewCyclesInterface
  settings?: PerformanceSettingsInterface
}

export const ReviewStats = ({ cycle, settings }: Props) => {
  const { data, isLoading, isError } = useGetReviewStageStats(cycle.id)

  const withReviewsCount = data?.employees_with_reviews ?? 0
  const totalCount = data?.total_employees ?? 0
  const employeesWithReviewPortion = withReviewsCount / totalCount

  const descriptionText = makeDescriptionText(withReviewsCount, totalCount)
  const portionText = makePortionText('employee', withReviewsCount, totalCount)
  const emptyPortionText = makeEmptyPortionText('scorecard')

  const reviewsDetails = data ? toReviewsDetails(data, settings) : []

  if (isError || totalCount === 0) {
    return null
  }

  return (
    <>
      <Progress
        value={employeesWithReviewPortion}
        title="Review progress"
        description={descriptionText}
        isLoading={isLoading}
        label={
          <PortionLabel
            dividend={withReviewsCount}
            divisor={totalCount}
            separator=" of "
            description={portionText}
            emptyDescription={emptyPortionText}
            highlight
          />
        }
      />
      <ReviewsDetails details={reviewsDetails} isLoading={isLoading} />
    </>
  )
}
