import { Flex, Text, Token, Icon, Tooltip, useTooltip, HStack } from '@revolut/ui-kit'
import { FinalGrade } from '@src/interfaces/performance'
import React from 'react'
import { gradeSettings } from '../utils'
import { formatPercentage } from '@src/utils/format'
import GradeCircle from '@components/GradeCircle'
import { CycleType } from '@src/pages/Forms/DepartmentForm/Performance/interfaces'

interface Props {
  grade: FinalGrade
  periodName: string | undefined
  percent: number | null
  cycleType?: CycleType
}

export const OverallProgressCircle = ({
  grade,
  periodName,
  percent,
  cycleType,
}: Props) => {
  const tooltip = useTooltip()

  const progressColor = gradeSettings?.[grade]?.color

  return (
    <Flex ml="s-12" mt="s-16" mb="s-4" gap="s-16" alignItems="center">
      <GradeCircle
        progressColor={progressColor}
        avatarColor={progressColor}
        periodName={periodName}
        gradeSettings={gradeSettings}
        grade={grade}
      />
      <Flex minWidth={110} gap="s-2" flexDirection="column">
        <HStack space="s-4" align="center">
          <Text
            variant="heading3"
            whiteSpace="nowrap"
            color={gradeSettings?.[grade]?.color}
          >
            {formatPercentage(percent)}
          </Text>
          <Icon
            name="InfoOutline"
            size={16}
            color={Token.color.greyTone50}
            style={{ cursor: 'pointer' }}
            {...tooltip.getAnchorProps()}
          />
          <Tooltip {...tooltip.getTargetProps()} width={308}>
            {`Overall score is calculated based on Department’s goals and roadmap progress
            this ${cycleType === CycleType.Year ? 'year' : 'review cycle'}.
             Final grade is published in the end of the year after results
            calibration`}
          </Tooltip>
        </HStack>
        <Text color={Token.color.greyTone50} whiteSpace="nowrap" variant="caption">
          {'Overall score'}
        </Text>
      </Flex>
    </Flex>
  )
}
