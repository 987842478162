import React, { useState } from 'react'
import { GenericError } from '@src/features/Form/FormErrorGuard'
import { HiringStagesListSkeleton } from '@src/features/JobPostingFlow/HiringProcess/HiringStagesListSkeleton'
import { HiringStage } from '@src/features/JobPostingFlow/HiringProcess/HiringStage'
import { HiringStageSidebar } from '@src/features/JobPostingFlow/HiringProcess/HiringStageSidebar'
import { HiringProcessInterface } from '@src/interfaces/hiringProccess'
import { ActionButton, DragAndDrop } from '@revolut/ui-kit'
import { HiringStageFormSidebar } from './HiringStageFormSidebar'
import { useSidebar } from '@src/hooks/useSidebar'
import { HiringStagesListSidebars } from '@src/features/JobPostingFlow/types'
import { useGetJobPostingHiringProcess } from '@src/api/jobPosting'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { JobPostingInterface } from '@src/interfaces/jobPosting'

export const HiringStagesList = () => {
  const { values } = useLapeContext<JobPostingInterface>()
  const [activeStage, setActiveStage] = useState<HiringProcessInterface | null>(null)
  const { sidebar, closeSidebar, toggleSidebar } = useSidebar<HiringStagesListSidebars>()
  const [activeDragId, setActiveDragId] = useState<string | number | null>(null)

  const { data, isLoading, refetch } = useGetJobPostingHiringProcess(values.id)

  if (isLoading) {
    return <HiringStagesListSkeleton />
  }

  if (!data) {
    return <GenericError />
  }

  const hiringProcessStages = data?.results || []

  const activeDragStage =
    activeDragId && hiringProcessStages.find(({ id }) => String(id) === activeDragId)

  return (
    <>
      {activeStage && sidebar === 'stageDetails' && (
        <HiringStageSidebar stage={activeStage} onClose={() => toggleSidebar(sidebar)} />
      )}

      {sidebar === 'stageForm' && (
        <HiringStageFormSidebar
          stage={activeStage}
          onClose={() => toggleSidebar('stageForm')}
          onAfterSubmit={() => {
            setActiveStage(null)
            closeSidebar()
            refetch()
          }}
        />
      )}

      <DragAndDrop.Provider
        onDragStart={event => {
          setActiveDragId(event.active.id)
        }}
        onDragEnd={() => {
          // @TODO PERF-6231 add backend call to reorder
        }}
        onDragCancel={() => {
          setActiveDragId(null)
        }}
      >
        <DragAndDrop.Sortable
          id="sortable"
          items={hiringProcessStages.map(({ id }) => String(id))}
        >
          {sortable => {
            const stage = hiringProcessStages.find(({ id }) => String(id) === sortable.id)
            if (!stage) {
              return null
            }
            return (
              <HiringStage
                disabled={sidebar === 'stageForm'}
                sortable={sortable}
                stage={stage}
                onClick={() => {
                  setActiveStage(stage)
                  toggleSidebar('stageDetails')
                }}
                onEdit={() => {
                  setActiveStage(stage)
                  toggleSidebar('stageForm')
                }}
                onAfterDelete={() => {
                  refetch()
                }}
              />
            )
          }}
        </DragAndDrop.Sortable>
        <DragAndDrop.DragOverlay>
          {activeDragStage && (
            <HiringStage
              key={activeDragStage.id}
              stage={activeDragStage}
              onAfterDelete={() => {}}
              onClick={() => {}}
              onEdit={() => {}}
              disabled
            />
          )}
        </DragAndDrop.DragOverlay>
      </DragAndDrop.Provider>

      <ActionButton
        useIcon="Plus"
        disabled={sidebar === 'stageForm'}
        onClick={() => {
          setActiveStage(null)
          toggleSidebar('stageForm')
        }}
      >
        Add stage
      </ActionButton>
    </>
  )
}
