import React, { useState } from 'react'
import {
  BottomSheet,
  Header,
  Button,
  Circle,
  Icon,
  Token,
  VStack,
  Text,
  Checkbox,
  DetailsCell,
  HStack,
  Grid,
} from '@revolut/ui-kit'
import { PerformanceType } from '@src/interfaces/goals'
import Tooltip from '@components/Tooltip/Tooltip'
import { LatencyDaysSelect } from './LatencyDaysSelect'

export interface AdvancedSettingsPayload {
  mandatorySetting: boolean
  latencyDays: number | null
  allowLateUpdates: boolean
}

interface Props {
  open: boolean
  performanceType: PerformanceType
  onConfirm: (payload: AdvancedSettingsPayload) => void
  onCancel: () => void
  latencyDays: number | null
}

export const AdvancedSettingsPopup = ({
  open,
  onCancel,
  performanceType,
  onConfirm,
  latencyDays,
}: Props) => {
  const [advancedSettings, setAdvancedSettings] = useState<AdvancedSettingsPayload>({
    mandatorySetting: performanceType?.id === 'mandatory',
    latencyDays,
    allowLateUpdates: Boolean(latencyDays),
  })

  const onChangeMandatorySetting = (mandatorySetting: boolean) => {
    setAdvancedSettings(prev => ({
      ...prev,
      mandatorySetting,
    }))
  }

  const onChangeLatencySetting = (value: number | null) => {
    setAdvancedSettings(prev => ({
      ...prev,
      latencyDays: value,
    }))
  }

  const onChangeAllowLateUpdatesSetting = (allowLateUpdates: boolean) => {
    setAdvancedSettings(prev => ({
      ...prev,
      allowLateUpdates,
    }))
  }

  return (
    <BottomSheet size="lg" open={open} onClose={onCancel}>
      <Header>
        <Header.Title>Advanced metric settings</Header.Title>
        <Header.Actions>
          <Circle bg={Token.color.widgetBackground} size={48}>
            <Icon name="Gear" size={24} />
          </Circle>
        </Header.Actions>
      </Header>
      <BottomSheet.Description>
        <VStack gap="s-16">
          <VStack gap="s-12">
            <Text variant="heading3">Metric attributes</Text>
            <DetailsCell>
              <DetailsCell.Title>
                <HStack gap="s-16" align="center">
                  <Checkbox
                    data-testid="mandatory-goal"
                    checked={advancedSettings.mandatorySetting}
                    onChange={event => onChangeMandatorySetting(event.target.checked)}
                  />
                  <Text variant="emphasis1" color={Token.color.foreground}>
                    Mandatory goal
                  </Text>
                </HStack>
              </DetailsCell.Title>
              <DetailsCell.Content>
                <Tooltip
                  placement="bottom"
                  text="Mandatory goals must have 100% progress by cycle end, otherwise Overall Progress will be set to zero."
                >
                  <Icon name="InfoOutline" size={24} />
                </Tooltip>
              </DetailsCell.Content>
            </DetailsCell>
          </VStack>
          <VStack gap="s-12">
            <HStack gap="s-4">
              <Text variant="heading3">Latency</Text>
              <Tooltip
                placement="top"
                text="When enabled, goal owners can update results for a specified number of days after the quarter ends. 
                Useful for goals that require additional time to finalise results"
              >
                <Icon name="InfoOutline" size={24} />
              </Tooltip>
            </HStack>

            <Grid columns="2fr 1fr" columnGap="s-16">
              <DetailsCell>
                <DetailsCell.Title>
                  <HStack gap="s-16" align="center">
                    <Checkbox
                      data-testid="latency-goal"
                      checked={advancedSettings.allowLateUpdates}
                      onChange={event =>
                        onChangeAllowLateUpdatesSetting(event.target.checked)
                      }
                    />
                    <Text variant="emphasis1" color={Token.color.foreground}>
                      Allow late updates
                    </Text>
                  </HStack>
                </DetailsCell.Title>
              </DetailsCell>
              <LatencyDaysSelect
                disabled={!advancedSettings.allowLateUpdates}
                value={advancedSettings.latencyDays}
                onChange={onChangeLatencySetting}
              />
            </Grid>
          </VStack>
        </VStack>
      </BottomSheet.Description>
      <BottomSheet.Actions horizontal>
        <Button onClick={onCancel} variant="secondary">
          Cancel
        </Button>
        <Button
          onClick={() => {
            onConfirm(advancedSettings)
          }}
          elevated
        >
          Confirm
        </Button>
      </BottomSheet.Actions>
    </BottomSheet>
  )
}
