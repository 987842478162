import React from 'react'
import { CellTypes, RowInterface } from '@src/interfaces/data'
import {
  ActionButton,
  Avatar,
  Ellipsis,
  Flex,
  Grid,
  Link,
  Text,
  Token,
} from '@revolut/ui-kit'
import { ProbationGoalInterface } from '@src/interfaces/probationReview'
import { getIconKeyByIssue } from '@src/utils/performance'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { TableNames } from '@src/constants/table'
import { selectorKeys } from '@src/constants/api'
import { ReviewCycleCategory } from '@src/interfaces/reviewCycles'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'
import Table from '@src/components/TableV2/Table'

interface Props {
  tickets: ProbationGoalInterface[]
  onAddGoalRedirect?: () => void
  type?: ReviewCycleCategory
  isLineManager?: boolean
}

const statusToColor = (status: string) => {
  switch (status) {
    case 'To Do':
      return Token.color.greyTone50
    case 'Done':
      return Token.color.green
    case 'In Progress':
      return Token.color.foreground
    default:
      return Token.color.foreground
  }
}

const ROW: RowInterface<ProbationGoalInterface> = {
  cells: [
    {
      type: CellTypes.insert,
      idPoint: 'name',
      dataPoint: 'name',
      sortKey: null,
      filterKey: null,
      selectorsKey: selectorKeys.none,
      title: 'Jira',
      width: 100,
      insert: ({ data }) => (
        <Table.ItemCell>
          <Grid gap="s-8" placeItems="center" columns="auto auto 1fr">
            {data.jira_issue_type && (
              <Avatar useIcon={getIconKeyByIssue(data.jira_issue_type)} />
            )}
            <Link href={data.jira_issue_url!} target="_blank" rel="noreferrer noopener">
              {data.jira_issue_key}
              <Text color={Token.color.foreground}>:</Text>
            </Link>
            <Ellipsis>
              <Text>{data.jira_issue_summary}</Text>
            </Ellipsis>
          </Grid>
        </Table.ItemCell>
      ),
    },
    {
      type: CellTypes.insert,
      idPoint: 'status',
      dataPoint: 'status',
      sortKey: null,
      filterKey: null,
      selectorsKey: selectorKeys.none,
      title: 'Progress',
      width: 20,
      insert: ({ data }) => (
        <Flex>
          {data.jira_issue_status ? (
            <Text
              color={statusToColor(data.jira_issue_status)}
              style={{ textTransform: 'capitalize' }}
            >
              {data.jira_issue_status.toLowerCase()}
            </Text>
          ) : (
            ' - '
          )}
        </Flex>
      ),
    },
  ],
}

export const TicketsView = ({
  tickets,
  onAddGoalRedirect,
  type,
  isLineManager,
}: Props) => {
  return (
    <AdjustableTable<ProbationGoalInterface>
      name={TableNames.ProbationGoals}
      row={ROW}
      count={tickets.length}
      data={tickets}
      hideHeader={tickets?.length === 0}
      hideCount
      emptyState={
        <EmptyTableRaw
          imageId="3D304"
          title={
            type === ReviewCycleCategory.Probation
              ? 'Probation goals pending'
              : 'PIP goals pending'
          }
          description={
            type === ReviewCycleCategory.Probation
              ? 'Line Manager needs to provide probation goals'
              : 'Line Manager needs to provide PIP goals'
          }
          action={
            type &&
            isLineManager && (
              <ActionButton useIcon="Plus" onClick={onAddGoalRedirect}>
                Add {type === ReviewCycleCategory.Probation ? 'probation' : 'PIP'}
              </ActionButton>
            )
          }
        />
      }
    />
  )
}
