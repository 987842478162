import React from 'react'

import { Box, Flex, Icon, Text, Token } from '@revolut/ui-kit'
import Tooltip from '@src/components/Tooltip/Tooltip'
import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { EngagementResultV2Interface } from '@src/interfaces/engagement'
import { selectorKeys } from '../api'

const HideDataIcon = (
  <Flex width="100%" justifyContent="flex-start">
    <Tooltip
      placement="top"
      body={
        <Box padding="s-8" width={210}>
          <Text color={Token.color.background}>
            This data is hidden to protect the anonymity of the respondents
          </Text>
        </Box>
      }
    >
      <Icon name="EyeHide" size={14} color={Token.color.greyTone50} />
    </Tooltip>
  </Flex>
)

export const engagementResultsV2QuestionNameColumn: ColumnInterface<EngagementResultV2Interface> =
  {
    type: CellTypes.text,
    idPoint: 'id',
    dataPoint: 'name',
    sortKey: 'name',
    filterKey: 'id',
    selectorsKey: selectorKeys.engagement_question_texts,
    title: 'Question',
  }

export const engagementResultsV2QuestionCategoryNameColumn: ColumnInterface<EngagementResultV2Interface> =
  {
    type: CellTypes.text,
    idPoint: 'driver',
    dataPoint: 'driver',
    sortKey: 'driver',
    filterKey: 'driver',
    selectorsKey: selectorKeys.engagement_drivers,
    title: 'Category',
  }

export const engagementResultsV2QuestionTypeColumn: ColumnInterface<EngagementResultV2Interface> =
  {
    type: CellTypes.text,
    idPoint: 'type',
    dataPoint: 'type',
    sortKey: 'type',
    filterKey: 'type',
    selectorsKey: selectorKeys.engagement_question_types,
    title: 'Question type',
  }

export const engagementResultsV2AverageScoreColumn: ColumnInterface<EngagementResultV2Interface> =
  {
    type: CellTypes.insert,
    idPoint: 'average_score',
    dataPoint: 'average_score',
    sortKey: 'average_score',
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Avg score',
    insert: ({ data }) => {
      if (data.type === 'open_ended' || data.average_score === null) {
        return <Text>-</Text>
      }
      if (data.can_show_results === false) {
        return HideDataIcon
      }
      return data.average_score ? Number(data.average_score.toFixed(2)) : '-'
    },
    headerTooltip: (
      <Box color={Token.color.background} p="s-8" minWidth={250}>
        The average score is equal to the average of all answers for each particular
        question or driver, it ranges from 1 to 5
      </Box>
    ),
  }

export const engagementResultsV2CategoryNameColumn: ColumnInterface<EngagementResultV2Interface> =
  {
    type: CellTypes.insert,
    idPoint: 'id',
    dataPoint: 'name',
    sortKey: 'name',
    filterKey: 'id',
    selectorsKey: selectorKeys.engagement_drivers,
    title: 'Category',
    insert: ({ data }) => (
      <Box py="s-12">
        <Text whiteSpace="pre-wrap">{data.name || '-'}</Text>
      </Box>
    ),
    notHoverable: true,
  }
