import { useScorecardsValidation } from '@src/features/ReviewCycle/PerformanceReviewCycle/hooks/useScorecardsValidation'
import React, { useEffect, useRef, useState } from 'react'
import {
  Action,
  Box,
  Button,
  Image,
  Popup,
  Relative,
  Scrollbar,
  Text,
  VStack,
  Widget,
} from '@revolut/ui-kit'
import {
  EligibleGroupInterface,
  ReviewCyclesInterface,
} from '@src/interfaces/reviewCycles'
import { useValidationStatusPopup } from '@src/features/ReviewCycle/PerformanceReviewCycle/CycleStages/Timeline/StageWidget/Popups/ValidateScorecards/hooks/useValidationStatusPopup'
import {
  PopupState,
  popupStateToPropsMap,
} from '@src/features/ReviewCycle/PerformanceReviewCycle/CycleStages/Timeline/StageWidget/Popups/ValidateScorecards/constants'
import { PopupBody } from '@src/features/ReviewCycle/PerformanceReviewCycle/CycleStages/Timeline/StageWidget/Popups/ValidateScorecards/PopupBody'
import { useNavigation } from '@src/features/ReviewCycle/PerformanceReviewCycle/hooks/useNavigation'
import { useSmoothProgress } from '@src/features/ReviewCycle/PerformanceReviewCycle/hooks/useSmoothProgress'

interface Props {
  cycle: ReviewCyclesInterface
  eligibleGroups: EligibleGroupInterface[]
  isOpen: boolean
  isConfirmDisabled?: boolean
  isConfirmPending?: boolean
  handleClose: VoidFunction
  handleOnValidConfirm: () => Promise<void>
}

export const ValidateScorecardsPopup = ({
  cycle,
  eligibleGroups,
  isOpen,
  isConfirmDisabled,
  isConfirmPending,
  handleClose,
  handleOnValidConfirm,
}: Props) => {
  const scrollRef = useRef<HTMLDivElement>(null)
  const [popupState, setPopupState] = useState(PopupState.Default)
  const {
    progress,
    isPending,
    isCompleted,
    isFailed,
    isValid,
    validate,
    reset: resetValidation,
  } = useScorecardsValidation(cycle.id)
  const { toEditEligibleEmployees } = useNavigation()
  const { showValidationOngoing, showValidationCompleted, showValidationFailed } =
    useValidationStatusPopup()

  const {
    value: smoothProgress,
    isRunning: isSmoothProgressRunning,
    start: startSmoothProgress,
    reset: resetSmoothProgress,
  } = useSmoothProgress(progress)

  useEffect(() => {
    if (isCompleted && !isSmoothProgressRunning) {
      showValidationCompleted()
      resetSmoothProgress()
      setPopupState(isValid ? PopupState.Valid : PopupState.Invalid)
    } else if (isFailed) {
      showValidationFailed()
      resetValidation()
      resetSmoothProgress()
    } else if (isSmoothProgressRunning) {
      showValidationOngoing(smoothProgress)
    }
  }, [isValid, isCompleted, isFailed, smoothProgress, isSmoothProgressRunning])

  const { title, description, confirmLabel, cancelLabel, image } =
    popupStateToPropsMap[popupState]

  const handleConfirm = async () => {
    if (popupState === PopupState.Valid) {
      await handleOnValidConfirm()
      handleClose()
      resetSmoothProgress()
    } else {
      validate()
      startSmoothProgress()
    }
  }

  const handleCancel = () => {
    handleClose()
    resetValidation()
    resetSmoothProgress()
  }

  return (
    <Popup size="sm" open={isOpen}>
      <VStack space="s-8" align="center">
        <Image size={88} image={image} />
        <Text variant="heading3">{title}</Text>
        <Text variant="caption">{description}</Text>
      </VStack>
      <Widget marginY="s-16">
        <Relative>
          <Box minHeight="s-8" maxHeight={300} borderRadius="r16" ref={scrollRef}>
            <PopupBody
              state={popupState}
              cycle={cycle}
              eligibleGroups={eligibleGroups}
              scrollRef={scrollRef}
            />
            <Scrollbar scrollRef={scrollRef} cornerSpace="s-8" offset="s-2" />
          </Box>
        </Relative>
        {popupState === PopupState.Default && (
          <Box px="s-16" pt="s-8" pb="s-16">
            <Action useIcon="PlusCircle" onClick={() => toEditEligibleEmployees(cycle)()}>
              Add employee group
            </Action>
          </Box>
        )}
      </Widget>
      <Popup.Actions>
        <Button
          onClick={handleConfirm}
          pending={isPending || isConfirmPending}
          disabled={isConfirmDisabled}
        >
          {confirmLabel}
        </Button>
        <Button variant="secondary" onClick={handleCancel}>
          {cancelLabel}
        </Button>
      </Popup.Actions>
    </Popup>
  )
}
