import React from 'react'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { TableNames } from '@src/constants/table'
import { EmptyTableRaw } from '@components/Table/EmptyTableRaw'
import { GoalsInterface } from '@src/interfaces/goals'
import { EntityTypes } from '@src/constants/api'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useTable } from '@components/Table/hooks'
import { goalsListTableRequests, GoalsStats } from '@src/api/goals'
import { getRow } from '@src/features/GrowthPlans/GrowthPlan/components/PerformanceSummary/Deliverables/Goals/Row'
import { useGoalsFilters } from '@src/features/GrowthPlans/GrowthPlan/components/PerformanceSummary/Deliverables/Goals/useGoalsFilters'
import { goalsWeightColumn } from '@src/constants/columns/goals'
import { SORT_DIRECTION, SortByInterface } from '@src/interfaces/data'
import { PerformanceSelector } from '@src/interfaces/performance'

interface Props {
  entityType: EntityTypes
  entityId: number
  reviewCycle: PerformanceSelector
}

const row = getRow()

const initialSortBy: SortByInterface[] = [
  {
    sortBy: String(goalsWeightColumn.sortKey),
    direction: SORT_DIRECTION.ASC,
    nonResettable: true,
  },
]

export const GoalsTable = ({ entityType, entityId, reviewCycle }: Props) => {
  const { data: goalsFilters, isLoading: isGoalsFiltersLoading } = useGoalsFilters(
    entityType,
    entityId,
    reviewCycle,
  )
  const table = useTable(goalsListTableRequests, goalsFilters, initialSortBy)

  const onRowClick = ({ id }: GoalsInterface) =>
    navigateTo(pathToUrl(ROUTES.FORMS.GOAL.PREVIEW, { id }))

  return (
    <AdjustableTable<GoalsInterface, GoalsStats>
      name={TableNames.Goals}
      row={row}
      disabledFiltering
      disabledSorting
      {...table}
      loading={isGoalsFiltersLoading || table.loading}
      emptyState={<EmptyTableRaw title="No Goals defined" imageSize={72} />}
      onRowClick={onRowClick}
    />
  )
}
