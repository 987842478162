import { filterSortPageIntoQuery } from '../utils/table'
import { api, apiV2, apiWithoutHandling } from '@src/api/index'
import { API } from '../constants/api'
import {
  GetRequestData,
  IdAndName,
  RequestInterface,
  RequestInterfaceNew,
  TableRequestInterface,
} from '../interfaces'
import { useFetch, useFetchV2 } from '@src/utils/reactQuery'
import {
  FullJobPostingInterface,
  JobPostingInterface,
  LinkedInPosting,
  PublishingStatuses,
  JobPostingStatsInterface,
  JobsStatsInterface,
  JobPostingListInterface,
  IndeedPosting,
} from '@src/interfaces/jobPosting'
import { SidebarJobDescription } from '@src/interfaces/jobDescription'
import { ApprovalFlowResponse } from '@src/interfaces/approvalFlow'
import { FilterByInterface, Stats } from '@src/interfaces/data'
import { generateRandomId } from '@src/utils/numbers'
import { HiringProcessInterface } from '@src/interfaces/hiringProccess'

export const jobPostingsRequests: RequestInterface<
  JobPostingInterface,
  Stats,
  JobPostingListInterface
> = {
  getItems: async ({ sortBy, filters, page }) =>
    api.get(API.JOB_POSTING, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
  getItem: async id => api.get(`${API.JOB_POSTING}/${id}`),
  patchItem: async (data, id) => api.patch(`${API.JOB_POSTING}/${id}`, data),
  deleteItem: async id => api.delete(`${API.JOB_POSTING}/${id}`),
  postItem: async data => api.post(API.JOB_POSTING, data),
}

export const useGetJobPostingStats = (filters: FilterByInterface[] = []) =>
  useFetch<JobPostingStatsInterface[]>(`/jobPostings/statistics`, undefined, {
    params: filterSortPageIntoQuery(undefined, filters),
  })

export const useGetJobPostings = (filters: FilterByInterface[], enabled?: boolean) => {
  const context = useFetch<GetRequestData<JobPostingInterface>>(
    API.JOB_POSTING,
    undefined,
    {
      params: filterSortPageIntoQuery(undefined, filters),
    },
    undefined,
    {
      enabled,
    },
  )

  return { ...context, data: context.data?.results }
}

export const useGetJobPosting = (id?: number | string) =>
  useFetch<JobPostingInterface>(id ? `${API.JOB_POSTING}/${id}` : null)

export const useGetRoleJobDescription = (id: number | null) =>
  useFetch<SidebarJobDescription[]>(id ? `${API.ROLES}/${id}/jobDescription` : null)

export const useGetSpecialisationJobDescription = (id: number | null) =>
  useFetch<FullJobPostingInterface>(
    id ? `${API.SPECIALISATIONS}/${id}/jobDescription` : null,
  )

export const useGetJobDescription = (id?: number) =>
  useFetch<FullJobPostingInterface>(
    id ? `${API.JOB_POSTING}/${id}/fullPosting` : null,
    undefined,
    undefined,
    undefined,
    {
      staleTime: 3000,
    },
  )

// we need to have stable ids to sort the sections
const transformJobPosting = (jobPosting: JobPostingInterface): JobPostingInterface => ({
  ...jobPosting,
  application_form_sections: jobPosting.application_form_sections.map(section => ({
    ...section,
    sortId: section.id || generateRandomId(),
  })),
})

// we dont't need to send sortId to the BE
const normalizeJobPostingBeforeSubmit = (jobPosting: Partial<JobPostingInterface>) =>
  jobPosting.application_form_sections
    ? {
        ...jobPosting,
        application_form_sections: jobPosting.application_form_sections.map(section => ({
          ...section,
          sortId: undefined,
        })),
      }
    : jobPosting

export const jobDescriptionFormRequest: RequestInterfaceNew<JobPostingInterface> = {
  get: async ({ id }) =>
    apiWithoutHandling
      .get<JobPostingInterface>(`${API.JOB_POSTING}/${id}`)
      .then(resp => ({
        ...resp,
        data: transformJobPosting(resp.data),
      })),
  update: async (data, { id }) =>
    apiWithoutHandling
      .patch(`${API.JOB_POSTING}/${id}`, normalizeJobPostingBeforeSubmit(data))
      .then(resp => ({
        ...resp,
        data: transformJobPosting(resp.data),
      })),
  submit: async data =>
    apiWithoutHandling
      .post(`${API.JOB_POSTING}`, normalizeJobPostingBeforeSubmit(data))
      .then(resp => ({
        ...resp,
        data: transformJobPosting(resp.data),
      })),
}

export const updateJobDescription = jobDescriptionFormRequest.update

export const useGetJobDescriptionApprovals = (id?: number) => {
  return useFetch<ApprovalFlowResponse>(id ? `${API.JOB_POSTING}/${id}/approvals` : null)
}

export const useGetLinkedInPostings = (jobPostingId?: number) => {
  return useFetch<GetRequestData<LinkedInPosting>>({
    url: API.LINKEDIN_POSTINGS,
    params: {
      params: {
        job_posting: jobPostingId,
      },
    },
    queryOptions: {
      enabled: !!jobPostingId,
    },
  })
}

export const useGetIndeedPostings = (jobPostingId?: number) => {
  return useFetch<GetRequestData<IndeedPosting>>({
    url: API.INDEED_POSTINGS,
    params: {
      params: {
        job_posting: jobPostingId,
      },
    },
    queryOptions: {
      enabled: !!jobPostingId,
    },
  })
}

export const linkedInPostingsRequests: TableRequestInterface<LinkedInPosting, undefined> =
  {
    getItems: async ({ sortBy, filters, page }) =>
      api.get(API.LINKEDIN_POSTINGS, {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      }),
  }

export const indeedPostingsRequests: TableRequestInterface<IndeedPosting, undefined> = {
  getItems: async ({ sortBy, filters, page }) =>
    api.get(API.INDEED_POSTINGS, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
}

export const promoteLinkedInPosting = (linkedInPostingId: number, promote: boolean) =>
  apiWithoutHandling.patch(`${API.LINKEDIN_POSTINGS}/${linkedInPostingId}/promote`, {
    is_promoted: promote,
  })

export const retryLinkedInPosting = (linkedInPostingId: number) =>
  apiWithoutHandling.patch(`${API.LINKEDIN_POSTINGS}/${linkedInPostingId}/retry`)

export const bulkEditJobPostings = (ids: number[], status: PublishingStatuses) =>
  api.patch(`/jobPostings/bulkEdit`, {
    ids,
    fields: { status },
  })

export const bulkEditRequisitionJobPostings = (
  ids: number[],
  fields: Record<string, IdAndName | IdAndName[]>,
) =>
  apiWithoutHandling.patch(
    `/requisitionPostingSimple/bulkEdit`,
    {
      ids,
      fields,
    },
    undefined,
    'v2',
  )

export const useGetJobsStats = () => {
  return useFetch<JobsStatsInterface>(`/jobs/stats`)
}

export const deleteJobPostingHiringProcessStage = (
  jobPostingId: number | string,
  stageId: number | string,
) => {
  return apiV2.delete(
    `${API.JOB_POSTING}/${jobPostingId}/jobPostingHiringStages/${stageId}`,
  )
}

export const createJobPostingHiringProcessStagesRequest = (
  jobPostingId: number | string,
): RequestInterfaceNew<HiringProcessInterface> => ({
  get: async ({ id }) =>
    apiV2.get(`${API.JOB_POSTING}/${jobPostingId}/jobPostingHiringStages/${id}`),
  update: async (data, { id }) =>
    apiV2.patch(`${API.JOB_POSTING}/${jobPostingId}/jobPostingHiringStages/${id}`, data),
  submit: async data =>
    apiV2.post(`${API.JOB_POSTING}/${jobPostingId}/jobPostingHiringStages`, data),
  delete: async ({ id }) => deleteJobPostingHiringProcessStage(jobPostingId, id!),
})

export const useGetJobPostingHiringProcess = (jobPostingId: number | string) =>
  useFetchV2<GetRequestData<HiringProcessInterface>>({
    url: `${API.JOB_POSTING}/${jobPostingId}/jobPostingHiringStages`,
  })
