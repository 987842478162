import React, { useEffect } from 'react'
import AdjustableTable, {
  AdjustableTableProps,
} from '@components/TableV2/AdjustableTable'
import { FilterByInterface, SortByInterface } from '@src/interfaces/data'
import { useTable } from '@components/Table/hooks'
import { tableRequests } from '@src/interfaces'
import { Flex } from '@revolut/ui-kit'
import { TableNames } from '@src/constants/table'

interface Props<T> extends Partial<AdjustableTableProps<T>> {
  name: TableNames
  initialFilter: FilterByInterface[]
  initialSort: SortByInterface[]
  getItems: tableRequests<T, unknown>['getItems']
  row: AdjustableTableProps<T>['row']
  filter?: FilterByInterface
}

const CXTable = <T extends {}>({
  initialFilter,
  initialSort,
  getItems,
  filter,
  ...props
}: Props<T>) => {
  const table = useTable<T>({ getItems }, initialFilter, initialSort)

  useEffect(() => {
    if (filter) {
      table.onFilterChange(filter, false)
    }
  }, [filter])

  return (
    <Flex mt="s-8" style={{ position: 'relative' }} flex="1 0">
      <AdjustableTable<T> useWindowScroll {...table} {...props} />
    </Flex>
  )
}

export default CXTable
