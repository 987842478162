import React from 'react'

import { getEngagementResultsTableV2Requests } from '@src/api/engagement'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'
import { useTable } from '@src/components/TableV2/hooks'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import { useIsNewTable } from '@src/components/TableV2/hooks'
import {
  engagementResultsV2AverageScoreColumn,
  engagementResultsV2CategoryNameColumn,
} from '@src/constants/columns/engagementResultsV2'
import { TableNames } from '@src/constants/table'
import { EngagementResultV2Interface } from '@src/interfaces/engagement'
import { ResultsInterface, useApplySurveyResultsFilters } from './common'

const row = {
  cells: [
    { ...engagementResultsV2CategoryNameColumn, width: 250 },
    { ...engagementResultsV2AverageScoreColumn, width: 105 },
  ],
}

export const ResultCategoriesTable = ({
  viewMode,
  surveyId,
  timelineFilter,
  scopeFilters,
  isLoading,
}: ResultsInterface) => {
  const isNewTable = useIsNewTable()
  const enableTableRequests = !isLoading && viewMode === 'table'

  const table = useTable<EngagementResultV2Interface>(
    getEngagementResultsTableV2Requests(surveyId, 'drivers'),
    undefined,
    undefined,
    { disable: !enableTableRequests, disableOnEmptyFilters: true },
  )
  useApplySurveyResultsFilters({
    enabled: enableTableRequests,
    table,
    timelineFilter,
    scopeFilters,
  })

  return (
    <AdjustableTable
      name={TableNames.EngagementSurveysResultCategories}
      row={row}
      hideCount={isNewTable}
      emptyState={<EmptyTableRaw title="Results were not found" />}
      useWindowScroll
      {...table}
    />
  )
}
