import React from 'react'
import { EmployeeInterface } from '@src/interfaces/employees'
import { PerformanceSelector, ReviewSummaryInterface } from '@src/interfaces/performance'
import { useGetPerformanceSettings } from '@src/api/performanceSettings'
import { Goals } from '@src/features/GrowthPlans/GrowthPlan/components/PerformanceSummary/Deliverables/Goals/Goals'
import { JiraGoals } from '@src/features/GrowthPlans/GrowthPlan/components/PerformanceSummary/Deliverables/JiraGoals/JiraGoals'
import { Widget } from '@revolut/ui-kit'
import Loader from '@components/CommonSC/Loader'
import { SmartGoals } from '@src/features/GrowthPlans/GrowthPlan/components/PerformanceSummary/Deliverables/SmartGoals/SmartGoals'

interface Props {
  employee: EmployeeInterface
  reviewCycle: PerformanceSelector
  performanceSummary?: ReviewSummaryInterface
}

export const Deliverables = ({ employee, performanceSummary, reviewCycle }: Props) => {
  const { data: performanceSettings, isLoading: isPerformanceSettingsLoading } =
    useGetPerformanceSettings()
  const hasJiraGoals = reviewCycle.probation_reviews_deliverables_type === 'jira'
  const hasSmartGoals =
    hasJiraGoals && !performanceSettings?.enable_probation_and_pip_goals_via_jira

  if (isPerformanceSettingsLoading) {
    return (
      <Widget height={150}>
        <Loader />
      </Widget>
    )
  }

  if (hasSmartGoals) {
    return <SmartGoals employee={employee} reviewCycle={reviewCycle} />
  }

  if (hasJiraGoals) {
    return <JiraGoals employee={employee} reviewCycle={reviewCycle} />
  }

  return (
    <Goals
      employee={employee}
      reviewCycle={reviewCycle}
      performanceSummary={performanceSummary}
    />
  )
}
