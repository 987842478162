import { useScorecardsValidation } from '@src/features/ReviewCycle/PerformanceReviewCycle/hooks/useScorecardsValidation'
import React, { useEffect, useRef, useState } from 'react'
import {
  Box,
  Button,
  Placeholder,
  Popup,
  Relative,
  Scrollbar,
  Widget,
} from '@revolut/ui-kit'
import { useStatusPopup } from './hooks/useStatusPopup'
import { InvalidState } from '@src/features/ReviewCycle/PerformanceReviewCycle/CycleStages/Timeline/StageWidget/Popups/ValidateScorecards/PopupBody'
import { ImageProps } from '@revolut/ui-kit'
import { useScrollParent } from '@src/features/ReviewCycle/PerformanceReviewCycle/hooks/useScrollParent'
import { useSmoothProgress } from '@src/features/ReviewCycle/PerformanceReviewCycle/hooks/useSmoothProgress'

enum PopupState {
  Default = 'Default',
  Valid = 'Valid',
  Invalid = 'Invalid',
}

interface Props {
  cycleId: number | string
  handleClose: VoidFunction
}

interface PopupElements extends Pick<ImageProps, 'image'> {
  title: string
  description?: string
  confirmLabel: string
  cancelLabel?: string
}

const popupStateToElementsMap: Record<PopupState, PopupElements> = {
  [PopupState.Default]: {
    title: 'Review eligible employees',
    description: 'Data for these employees will be validated',
    confirmLabel: 'Validate employee data',
    cancelLabel: 'Cancel',
    image: {
      default: `https://assets.revolut.com/assets/3d-images-v2/3D262.png`,
      '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D262@2x.png',
      '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D262@3x.png',
    },
  },
  [PopupState.Valid]: {
    title: 'Employee data is valid',
    confirmLabel: 'Finish',
    image: {
      default: `https://assets.revolut.com/assets/3d-images-v2/3D151A.png`,
      '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D151A@2x.png',
      '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D151A@3x.png',
    },
  },
  [PopupState.Invalid]: {
    title: 'We found some issues',
    confirmLabel: 'Validate again',
    cancelLabel: 'Cancel',
    image: {
      default: `https://assets.revolut.com/assets/3d-images-v2/3D354.png`,
      '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D354@2x.png',
      '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D354@3x.png',
    },
  },
}

export const ValidationEmployeePopup = ({ cycleId, handleClose }: Props) => {
  const scrollRef = useRef<HTMLDivElement>(null)
  const scrollParent = useScrollParent(scrollRef)
  const [popupState, setPopupState] = useState(PopupState.Default)

  const {
    progress,
    isPending,
    isCompleted,
    isFailed,
    isValid,
    validate,
    reset: resetValidation,
  } = useScorecardsValidation(cycleId)
  const { showValidationOngoing, showValidationCompleted, showValidationFailed } =
    useStatusPopup()

  const {
    value: smoothProgress,
    isRunning: isSmoothProgressRunning,
    start: startSmoothProgress,
    reset: resetSmoothProgress,
  } = useSmoothProgress(progress)

  useEffect(() => {
    if (isCompleted && !isSmoothProgressRunning) {
      showValidationCompleted()
      resetSmoothProgress()
      setPopupState(isValid ? PopupState.Valid : PopupState.Invalid)
    } else if (isFailed) {
      showValidationFailed()
      resetValidation()
      resetSmoothProgress()
    } else if (isSmoothProgressRunning) {
      showValidationOngoing(smoothProgress)
    }
  }, [isValid, isCompleted, smoothProgress, isFailed, isSmoothProgressRunning])

  const handleConfirm = () => {
    if (popupState === PopupState.Valid) {
      handleClose()
      resetSmoothProgress()
    } else {
      validate()
      startSmoothProgress()
    }
  }

  const handleCancel = () => {
    handleClose()
    resetValidation()
    resetSmoothProgress()
  }

  const { title, description, confirmLabel, cancelLabel, image } =
    popupStateToElementsMap[popupState]
  const hasContent = popupState === PopupState.Invalid

  return (
    <Popup size="sm" defaultOpen onClose={handleClose}>
      <Placeholder>
        <Placeholder.Image image={image} />
        <Placeholder.Title>{title}</Placeholder.Title>
        <Placeholder.Description>{description}</Placeholder.Description>
      </Placeholder>
      <Widget my={hasContent ? 's-16' : undefined}>
        <Relative>
          <Box
            minHeight={hasContent ? 's-8' : 0}
            maxHeight={300}
            borderRadius="r16"
            ref={scrollRef}
          >
            {hasContent && <InvalidState cycleId={cycleId} scrollParent={scrollParent} />}
            <Scrollbar scrollRef={scrollRef} cornerSpace="s-8" offset="s-2" />
          </Box>
        </Relative>
      </Widget>
      <Popup.Actions>
        <Button onClick={handleConfirm} pending={isPending}>
          {confirmLabel}
        </Button>
        {popupState !== PopupState.Valid && (
          <Button variant="secondary" onClick={handleCancel}>
            {cancelLabel}
          </Button>
        )}
      </Popup.Actions>
    </Popup>
  )
}
