import React from 'react'
import { JobPostingInterface } from '@src/interfaces/jobPosting'
import {
  Avatar,
  Button,
  Item,
  List,
  Subheader,
  Text,
  Token,
  VStack,
} from '@revolut/ui-kit'
import { PageBody } from '@src/components/Page/PageBody'
import { useParams } from 'react-router-dom'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { JobPostingFlowParams } from '@src/features/JobPostingFlow/types'
import { HiringStagesList } from '@src/features/JobPostingFlow/HiringProcess/HiringStagesList'
import { PageActions } from '@components/Page/PageActions'
import { InternalLink } from '@components/InternalLink/InternalLink'

interface HiringProcessProps {
  onAfterSubmit: VoidFunction
}

export const HiringProcess = ({ onAfterSubmit }: HiringProcessProps) => {
  const params = useParams<JobPostingFlowParams>()

  return (
    <>
      <PageBody>
        <VStack gap="s-16">
          <Item>
            <Item.Avatar>
              <Avatar useIcon="BulkSelection" />
            </Item.Avatar>
            <Item.Content>
              <Item.Description>
                This is your default hiring process template. You can add, remove and
                modify stages. Any changes you make will only apply to this job posting
              </Item.Description>
            </Item.Content>
          </Item>
          <Item>
            <Item.Avatar>
              <Avatar useIcon="Lightbulb" />
            </Item.Avatar>
            <Item.Content>
              <Item.Title>Hiring Process recommendations</Item.Title>
              <Item.Description>
                We recommend following these steps to achieve high quality of hiring
                process
              </Item.Description>
              <List variant="compact" pt="s-8">
                <List.Item useIcon="Check" color={Token.color.success}>
                  <Text variant="body2" color={Token.color.greyTone50} fontWeight={700}>
                    Experience:{' '}
                  </Text>
                  <Text variant="body2" color={Token.color.greyTone50}>
                    Include CV Screening stage.
                  </Text>
                </List.Item>
                <List.Item useIcon="ExclamationTriangle" color={Token.color.warning}>
                  <Text variant="body2" color={Token.color.greyTone50} fontWeight={700}>
                    Skills:{' '}
                  </Text>
                  <Text variant="body2" color={Token.color.greyTone50}>
                    Make sure skills are tested. This hiring process does not test any
                    candidate skills
                  </Text>
                </List.Item>
                <List.Item useIcon="Check" color={Token.color.success}>
                  <Text variant="body2" color={Token.color.greyTone50} fontWeight={700}>
                    Values:{' '}
                  </Text>
                  <Text variant="body2" color={Token.color.greyTone50}>
                    Asses candidate against company values
                  </Text>
                </List.Item>
              </List>
            </Item.Content>
          </Item>
          <Subheader>
            <Subheader.Title>Hiring stages</Subheader.Title>
          </Subheader>
          <HiringStagesList />
        </VStack>
      </PageBody>
      <PageActions>
        <Button
          use={InternalLink}
          onClick={() => {
            navigateTo(pathToUrl(ROUTES.FORMS.JOB_POSTING_FLOW.APPLICATION_FORM, params))
          }}
          variant="secondary"
          elevated
        >
          Back
        </Button>
        <NewSaveButtonWithPopup<JobPostingInterface>
          useValidator
          hideWhenNoChanges={false}
          noPopup
          onAfterSubmit={() => {
            onAfterSubmit()
            navigateTo(pathToUrl(ROUTES.FORMS.JOB_POSTING_FLOW.PUBLISH, params))
          }}
        >
          Next
        </NewSaveButtonWithPopup>
      </PageActions>
    </>
  )
}
