import React from 'react'
import { HStack } from '@revolut/ui-kit'
import FilterButtonRadioSelect from '@src/components/FilterButtonRadioSelect/FilterButtonRadioSelect'
import FilterButtonCheckboxSelect from '@src/components/FilterButtonCheckboxSelect/FilterButtonCheckboxSelect'
import { usePayCycles, usePaygroup } from '@src/api/payroll'
import { useGetSelectors } from '@src/api/selectors'
import { selectorKeys } from '@src/constants/api'
import { FilterByInterface, FilterOption } from '@src/interfaces/data'
import { OptionInterface } from '@src/interfaces/selectors'
import { useTableReturnType } from '@src/components/Table/hooks'
import { GetRequestData } from '@src/interfaces'
import {
  PayCyclePaymentInterface,
  PayCyclePaymentStatsInterface,
  PayrollElementInterface,
} from '@src/interfaces/payroll'

export const FilterButtons = ({
  table,
}: {
  table: useTableReturnType<
    PayCyclePaymentInterface,
    PayCyclePaymentStatsInterface,
    GetRequestData<PayCyclePaymentInterface>
  >
}) => {
  const filterChange = (selected: {
    [columnName: string]: OptionInterface[] | OptionInterface
  }) => {
    const filterColumns = Object.keys(selected)

    const filters = filterColumns.map(columnName => ({
      filters: Array.isArray(selected[columnName])
        ? selected[columnName]
        : [selected[columnName]],
      columnName,
      nonResettable: true,
    }))

    table.onFilterChange(filters as FilterByInterface[])
  }

  const filterGet = (columnName: string) => {
    const filterObj = table.filterBy.find(filter => filter.columnName === columnName)
    return filterObj ? filterObj.filters : undefined
  }

  const { data: paygroups } = useGetSelectors(selectorKeys.active_pay_groups)
  const { selectablePaycycles } = usePayCycles(
    filterGet('pay_cycle__pay_group_id')?.[0]?.id.toString(),
  )
  const { data: selectedPaygroupData, selectableSettlementProfiles } = usePaygroup(
    filterGet('pay_cycle__pay_group_id')?.[0]?.id,
  )

  return (
    <HStack gap="s-8">
      <FilterButtonRadioSelect
        label="Pay group"
        options={paygroups || []}
        value={filterGet('pay_cycle__pay_group_id')?.[0] || null}
        onChange={selectedPaygroup =>
          filterChange({
            pay_cycle__pay_group_id: selectedPaygroup || [],
            pay_cycle__id: [],
            payroll_element__id: [],
            payroll_element__settlement_profile: [],
          })
        }
        searchable
      />
      <FilterButtonCheckboxSelect<FilterOption>
        label="Cycle"
        disabled={!filterGet('pay_cycle__pay_group_id')}
        onChange={selectedPaycycles =>
          filterChange({ pay_cycle__id: selectedPaycycles || [] })
        }
        options={selectablePaycycles || []}
        value={filterGet('pay_cycle__id') || []}
        searchable
      />
      <FilterButtonCheckboxSelect<PayrollElementInterface | FilterOption>
        label="Element"
        disabled={!filterGet('pay_cycle__pay_group_id')}
        options={selectedPaygroupData?.payroll_elements || []}
        value={filterGet('payroll_element__id') || []}
        onChange={selectedPayrollElements =>
          filterChange({ payroll_element__id: selectedPayrollElements || [] })
        }
        searchable
      />
      <FilterButtonRadioSelect
        label="Settlement profile"
        disabled={!filterGet('pay_cycle__pay_group_id')}
        options={selectableSettlementProfiles || []}
        value={filterGet('payroll_element__settlement_profile')?.[0] || null}
        onChange={selectedProfile =>
          filterChange({
            payroll_element__settlement_profile: selectedProfile || [],
          })
        }
        searchable
      />
    </HStack>
  )
}
