import { ROUTES } from '@src/constants/routes'
import Form from '@src/features/Form/Form'
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { SettingsLanding } from './Landing'
import { CycleSettings } from './Cycle'
import { PipSettings } from './PIP'
import { ProbationSettings } from './Probation'
import { InnerRoute } from '../common/InnerRoute'
import { API } from '@src/constants/api'
import { ProbationTemplate } from './ProbationTemplateForm'
import { InnerSettingsRoute } from './common/InnerSettingsRoute'
import { GeneralSettings } from '@src/apps/Performance/Settings/GeneralSettings'
import { performanceSettings } from '@src/api/performanceSettings'
import { ReviewSettings } from '@src/pages/Settings/Performance/ReviewSettings/ReviewSettings'
import { ManageEvaluationCriteria } from './ManageEvaluationCriteria'

export const Settings = () => {
  return (
    <Form api={performanceSettings} invalidateQueries={[API.PERFORMANCE_SETTINGS]}>
      <Switch>
        <InnerSettingsRoute
          path={ROUTES.PERFORMANCE.SCORECARD_SETUP.SETTINGS.LANDING}
          title="Review settings"
          description="Settings for performance functionalities"
          backPath={ROUTES.PERFORMANCE.SCORECARD_PREVIEW}
          showActions={false}
        >
          <SettingsLanding />
        </InnerSettingsRoute>
        <InnerSettingsRoute
          path={ROUTES.PERFORMANCE.SCORECARD_SETUP.SETTINGS.GENERAL}
          title="General settings"
          backPath={ROUTES.PERFORMANCE.SCORECARD_SETUP.SETTINGS.LANDING}
        >
          <GeneralSettings />
        </InnerSettingsRoute>

        <InnerSettingsRoute
          path={ROUTES.PERFORMANCE.SCORECARD_SETUP.SETTINGS.CYCLE}
          title="Cycle duration"
          backPath={ROUTES.PERFORMANCE.SCORECARD_SETUP.SETTINGS.LANDING}
        >
          <CycleSettings />
        </InnerSettingsRoute>

        <InnerSettingsRoute
          path={ROUTES.PERFORMANCE.SCORECARD_SETUP.SETTINGS.REVIEWS}
          title="Review types"
          backPath={ROUTES.PERFORMANCE.SCORECARD_SETUP.SETTINGS.LANDING}
        >
          <ReviewSettings />
        </InnerSettingsRoute>

        <InnerSettingsRoute
          path={ROUTES.PERFORMANCE.SCORECARD_SETUP.SETTINGS.PIP}
          title="PIP settings"
          backPath={ROUTES.PERFORMANCE.SCORECARD_SETUP.SETTINGS.LANDING}
        >
          <PipSettings />
        </InnerSettingsRoute>

        <InnerSettingsRoute
          path={ROUTES.PERFORMANCE.SCORECARD_SETUP.SETTINGS.PROBATION}
          title="Probation settings"
          backPath={ROUTES.PERFORMANCE.SCORECARD_SETUP.SETTINGS.LANDING}
        >
          <ProbationSettings
            addTemplateLink={
              ROUTES.PERFORMANCE.SCORECARD_SETUP.SETTINGS.PROBATION_TEMPLATE
            }
          />
        </InnerSettingsRoute>

        <Route path={ROUTES.PERFORMANCE.SCORECARD_SETUP.SETTINGS.PROBATION_TEMPLATE}>
          <InnerRoute
            title="New Probation Template"
            description="Create a new probation cycle template"
            backPath={ROUTES.PERFORMANCE.SCORECARD_SETUP.SETTINGS.PROBATION}
          >
            <ProbationTemplate />
          </InnerRoute>
        </Route>
        <Route path={ROUTES.PERFORMANCE.SCORECARD_SETUP.SETTINGS.EVALUATION_CRITERIA}>
          <InnerRoute
            title="Manage Evaluation Criteria"
            backPath={ROUTES.PERFORMANCE.SCORECARD_SETUP.SETTINGS.LANDING}
          >
            <ManageEvaluationCriteria />
          </InnerRoute>
        </Route>
      </Switch>
    </Form>
  )
}
