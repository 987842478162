import { useEffect, useMemo } from 'react'
import { isEqual } from 'lodash'

import { useTableReturnType } from '@src/components/TableV2/hooks'
import { ROUTES } from '@src/constants/routes'
import { FilterByInterface } from '@src/interfaces/data'
import {
  EngagementResultsScope,
  GroupByOption,
  GroupByOptionItem,
} from '@src/interfaces/engagement'
import { OptionInterface } from '@src/interfaces/selectors'
import { toIdAndName } from '@src/utils/toIdAndName'
import { UseTimelineFilterReturnType } from './TimelineFilter/useTimelineFilter'

export const MAX_ITEMS_ALLOWED_FOR_ANALYSIS = 10

const mainEntrypointBaseRoutes = [
  ROUTES.PERFORMANCE.ENGAGEMENT.SURVEY_RESULTS,
  ROUTES.APPS.ENGAGEMENT.SURVEY_RESULTS,
]

const entityLevelEntrypointBaseRoutes = [
  ROUTES.ORGANISATION.COMPANY.ENGAGEMENT,
  ROUTES.FORMS.DEPARTMENT.ENGAGEMENT,
  ROUTES.FORMS.TEAM.ENGAGEMENT,
  ROUTES.FORMS.ROLE.ENGAGEMENT,
  ROUTES.FORMS.FUNCTION.ENGAGEMENT,
  ROUTES.FORMS.SPECIALISATIONS.ENGAGEMENT,
  ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.ENGAGEMENT,
]

export const allSurveyResultsBaseRoutes = [
  ...mainEntrypointBaseRoutes,
  ...entityLevelEntrypointBaseRoutes,
]

export type ViewMode = 'table' | 'heatmap'
export type TimelineFilterMode = 'rounds' | 'calendar'
export type ItemsToAnalyse = 'questions' | 'categories'

export type UseHeatmapFiltersReturnType = {
  value: GroupByOptionItem[] | undefined
  setValue: (newValue: OptionInterface[]) => void
  groupByOptions: GroupByOption[] | undefined
  isLoadingGroupByOptions: boolean
  groupBy: {
    value: GroupByOption
    setValue: (newValue: GroupByOption) => void
    availableItems: GroupByOption[]
    isLoadingAvailableItems: boolean
    paramKey: string | undefined
    paramValue: string | undefined
  }
  clearAll: VoidFunction
}
export type ResultsInterface = {
  viewMode: ViewMode
  surveyId: number
  timelineFilter: UseTimelineFilterReturnType
  heatmapFilters: UseHeatmapFiltersReturnType
  scopeFilters?: FilterByInterface[]
  itemsToAnalyse: ItemsToAnalyse
  isLoading: boolean
  entity?: { type: EngagementResultsScope; id: number }
}

export type PublishedResultsScope =
  | 'company'
  | 'department'
  | 'function'
  | 'role'
  | 'specialisation'
  | 'team'
  | 'employee'

export const useApplySurveyResultsFilters = <T>({
  enabled,
  table,
  timelineFilter,
  heatmapFilters,
  scopeFilters,
  itemsToAnalyse,
  onRefresh,
  isHeatmap,
}: {
  enabled: boolean
  table: useTableReturnType<T>
  timelineFilter: UseTimelineFilterReturnType
  heatmapFilters?: UseHeatmapFiltersReturnType
  scopeFilters?: FilterByInterface[]
  itemsToAnalyse?: ItemsToAnalyse
  onRefresh?: VoidFunction
  isHeatmap?: boolean
}) => {
  const { dateFrom, dateTo } = timelineFilter
  const groupByKey = heatmapFilters?.groupBy.paramKey
  const groupByValue = heatmapFilters?.groupBy.paramValue

  const invalidDateRange = Boolean(dateFrom == null || dateTo == null)
  const invalidHeatmapFilters =
    isHeatmap && (!groupByKey || heatmapFilters.value === undefined)
  const invalidFilters = invalidDateRange || invalidHeatmapFilters

  const allFilters = useMemo(() => {
    const dateRangeFilterParams: FilterByInterface[] = [
      {
        columnName: 'from_date',
        filters: [toIdAndName(timelineFilter.dateFrom)],
        nonInheritable: true,
        nonResettable: true,
      },
      {
        columnName: 'to_date',
        filters: [toIdAndName(dateTo)],
        nonInheritable: true,
        nonResettable: true,
      },
    ]
    const heatmapFilterParams: FilterByInterface[] =
      isHeatmap && groupByKey
        ? [
            {
              columnName: 'group_by',
              filters: [toIdAndName(groupByKey)],
              nonInheritable: true,
              nonResettable: true,
            },
            ...(groupByValue
              ? [
                  {
                    columnName: groupByKey,
                    filters: [toIdAndName(groupByValue)],
                    nonInheritable: true,
                    nonResettable: true,
                  },
                ]
              : []),
          ]
        : []

    return [...dateRangeFilterParams, ...heatmapFilterParams, ...(scopeFilters || [])]
  }, [dateFrom, dateTo, groupByKey, groupByValue])

  useEffect(() => {
    if (!enabled || invalidFilters) {
      return
    }
    if (!isEqual(allFilters, table.filterBy)) {
      table.resetFiltersAndSorting(allFilters)
      onRefresh?.()
    }
  }, [enabled, invalidFilters, allFilters])

  useEffect(() => {
    table.resetFiltersAndSorting(allFilters)
    onRefresh?.()
  }, [itemsToAnalyse])
}
