import React from 'react'
import { ReviewCyclesInterface, ReviewCycleStage } from '@src/interfaces/reviewCycles'
import { formatStagePeriod } from '@src/features/ReviewCycle/PerformanceReviewCycle/formatters/formatStagePeriod'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { EditLink } from '@src/features/ReviewCycle/PerformanceReviewCycle/CycleStages/Timeline/StageWidget/Details/EditLink'
import { Flex } from '@revolut/ui-kit'
import { timelineModel } from '@src/features/ReviewCycle/PerformanceReviewCycle/models/TimelineModel'
import { stageToEditLinkLabelMap } from '@src/features/ReviewCycle/PerformanceReviewCycle/CycleStages/Timeline/StageWidget/Details/constants'

interface Props {
  cycle: ReviewCyclesInterface
  stage: ReviewCycleStage
}

export const StagePeriods = ({ cycle, stage }: Props) => {
  const { id } = cycle
  const editPath = pathToUrl(ROUTES.FORMS.REVIEW_CYCLES.TIMELINE, { id })
  const subStages = timelineModel.getSubStages(stage)

  return (
    <Flex gap="s-16" flexWrap="wrap">
      {(subStages.length ? subStages : [stage]).map(period => (
        <EditLink
          key={period}
          to={editPath}
          label={stageToEditLinkLabelMap[period]}
          linkText={formatStagePeriod(cycle, period, false) ?? 'No period defined'}
        />
      ))}
    </Flex>
  )
}
