import React from 'react'
import { CellTypes, ColumnInterface, FilterType } from '@src/interfaces/data'
import { selectorKeys } from '../api'
import { EngagementSurveyRoundInterface } from '@src/interfaces/engagement'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import { Text } from '@revolut/ui-kit'
import {
  mapSurveyRoundStatusToColor,
  participationRateToColor,
} from '@src/apps/People/Engagement/helpers'

export const surveyHistorySentOnColumn: ColumnInterface<EngagementSurveyRoundInterface> =
  {
    type: CellTypes.date,
    idPoint: 'sent_on',
    dataPoint: 'sent_on',
    sortKey: 'sent_on',
    filterKey: 'sent_on',
    selectorsKey: selectorKeys.none,
    title: 'Sent on',
  }

export const surveyHistorySentByColumn: ColumnInterface<EngagementSurveyRoundInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'sent_by.id',
    dataPoint: 'sent_by.full_name',
    sortKey: 'sent_by__full_name',
    filterKey: 'sent_by__id',
    selectorsKey: selectorKeys.none,
    insert: ({ data }) => <UserWithAvatar {...data.sent_by} />,
    title: 'Sent by',
  }

export const surveyHistoryQuestionsNumColumn: ColumnInterface<EngagementSurveyRoundInterface> =
  {
    type: CellTypes.text,
    idPoint: 'total_questions',
    dataPoint: 'total_questions',
    sortKey: 'total_questions',
    filterKey: 'total_questions',
    selectorsKey: selectorKeys.none,
    title: 'Total questions',
  }

export const surveyHistoryAudienceSizeColumn: ColumnInterface<EngagementSurveyRoundInterface> =
  {
    type: CellTypes.text,
    filterType: FilterType.range,
    idPoint: 'audience_size',
    dataPoint: 'audience_size',
    sortKey: 'audience_size',
    filterKey: 'audience_size',
    selectorsKey: selectorKeys.none,
    title: 'Recipients',
  }

export const surveyHistoryParticipationRate: ColumnInterface<EngagementSurveyRoundInterface> =
  {
    type: CellTypes.insert,
    filterType: FilterType.range,
    idPoint: 'participation_rate',
    dataPoint: 'participation_rate',
    sortKey: 'participation_rate',
    filterKey: 'participation_rate',
    selectorsKey: selectorKeys.none,
    title: 'Participation',
    insert: ({ data }) => {
      if (typeof data.participation_rate !== 'number') {
        return '-'
      }
      const percentage = Math.round(data.participation_rate * 100)
      return <Text color={participationRateToColor(percentage)}>{percentage}%</Text>
    },
  }

export const surveyHistoryStatusColumn: ColumnInterface<EngagementSurveyRoundInterface> =
  {
    type: CellTypes.text,
    idPoint: 'status.id',
    dataPoint: 'status.name',
    sortKey: 'status',
    filterKey: 'status',
    colors: mapSurveyRoundStatusToColor,
    selectorsKey: selectorKeys.engagement_survey_round_statuses,
    title: 'Status',
  }

export const surveyHistoryActionsColumn: ColumnInterface<EngagementSurveyRoundInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'id',
    dataPoint: 'id',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Actions',
    notHoverable: true,
  }
