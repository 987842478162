import React, { useState } from 'react'
import { ReviewSummaryInterface } from '@src/interfaces/performance'
import { connect } from 'lape'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { Card } from '@src/pages/Forms/EmployeePerformanceLayout/Card'
import { Target } from '@revolut/icons'
import {
  CardContentTypes,
  getFinalGradeTitleFromRating,
  useScoreCardGrades,
  useGetSegmentedDeliverablesEnabled,
} from '@src/pages/Forms/EmployeePerformanceLayout/utils'
import { KPI } from '@src/pages/Forms/EmployeePerformanceLayout/Sections/KPI'
import { Deliverables } from '@src/pages/Forms/EmployeePerformanceLayout/Sections/Deliverables'
import { VStack } from '@revolut/ui-kit'
import { getHideFinalRating } from '@src/pages/Forms/EmployeePerformanceViewLayout/utils'
import { get } from 'lodash'
import { IndividualGoalsList } from '@src/pages/Forms/EmployeePerformanceLayout/components/IndividualGoalsList'
import { TeamGoalsList } from '@src/pages/Forms/EmployeePerformanceLayout/components/TeamGoalsList'
import { useGetSegmentedDeliveryGrades } from '../../EmployeePerformanceLayout/getSegmentedDeliveryGrades'

export const DeliverablesCardView = connect(() => {
  const { values } = useLapeContext<ReviewSummaryInterface>()
  const hasKPI = !!values.summary?.kpis_section
  const hasDeliverables = hasKPI || !!values.summary?.deliverables
  const gradesMap = values.grade_label_mapping
  const [goalsStat, setGoalsStat] = useState<number>()
  const getSegmentedDeliverablesGrades = useGetSegmentedDeliveryGrades()

  const goalDeliverablesGrades = useScoreCardGrades()

  const isSegmentedDeliverables = useGetSegmentedDeliverablesEnabled()

  if (!hasDeliverables) {
    return null
  }

  const hideFinalRating = getHideFinalRating(values)

  const deliverablesFields = isSegmentedDeliverables
    ? values.summary?.deliverables?.cards?.map((card, ind) => {
        return {
          field: `summary.deliverables.cards.${ind}.rating`,
          title: card.name,
          grades: getSegmentedDeliverablesGrades(
            get(values, `summary.deliverables.cards.${ind}.criteria_key`),
          ),
          cardJustification: null,
        }
      }) || []
    : [
        {
          field: `summary.deliverables.rating`,
          title: 'Contribution and impact',
          grades: goalDeliverablesGrades,
          cardJustification: null,
        },
      ]

  return (
    <>
      {hasKPI ? (
        <Card
          data={values}
          renderExpandedContent={() => (
            <KPI
              isViewMode
              reviewData={values.summary}
              kpiGrades={goalDeliverablesGrades}
              gradesMap={gradesMap}
            />
          )}
          additionalInfo={
            <IndividualGoalsList
              gradesMap={gradesMap}
              setGoalsStat={setGoalsStat}
              employeeId={values.reviews[0]?.reviewed_employee.id}
              cycle={values.reviews[0]?.cycle}
            />
          }
          stat={goalsStat}
          type={CardContentTypes.KPI}
          title="Goals"
          icon={Target}
          fields={[
            {
              field: 'summary.kpis_section.rating',
              title: 'Contribution and impact',
              grades: goalDeliverablesGrades,
              cardJustification: null,
            },
          ]}
          finalRating={
            hideFinalRating
              ? undefined
              : getFinalGradeTitleFromRating(
                  gradesMap,
                  values.summary?.kpis_section?.rating,
                )
          }
          isViewMode
        />
      ) : (
        <Card
          data={values}
          renderExpandedContent={expContentField => (
            <Deliverables
              isViewMode
              reviewData={values.summary}
              deliverablesGrades={
                isSegmentedDeliverables
                  ? getSegmentedDeliverablesGrades(
                      get(
                        values,
                        `summary.deliverables.cards.${expContentField.cardIndex}.criteria_key`,
                      ),
                    )
                  : goalDeliverablesGrades
              }
              gradesMap={gradesMap}
              selectedField={expContentField}
              isSegmentedDeliverables={!!isSegmentedDeliverables}
            />
          )}
          additionalInfo={
            isSegmentedDeliverables ? undefined : (
              <VStack space="s-16">
                <TeamGoalsList
                  showBanner
                  isNewDesign
                  gradesMap={gradesMap}
                  cycle={values.reviews[0]?.cycle}
                  team={values.team}
                />
              </VStack>
            )
          }
          type={CardContentTypes.DELIVERABLES}
          title={isSegmentedDeliverables ? 'Deliverables' : 'Goals'}
          icon={Target}
          fields={deliverablesFields}
          justification={values?.summary?.deliverables?.skipped_section_justifications}
          finalRating={
            hideFinalRating
              ? undefined
              : getFinalGradeTitleFromRating(
                  gradesMap,
                  values.summary?.deliverables?.rating,
                )
          }
          isViewMode
        />
      )}
    </>
  )
})
