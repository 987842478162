import React, { useEffect, useRef, useState } from 'react'
import { ActionButton, Avatar, Item, useToggle, VStack, Widget } from '@revolut/ui-kit'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  GrowthPlanActionContentObjectInterface,
  GrowthPlanActionContentTypeInterface,
  GrowthPlanActionInterface,
  GrowthPlanInterface,
} from '@src/interfaces/growthPlans'
import { transparentThemeBackgroundOverrides } from '@src/styles/theme'
import { ActionItem } from '@src/features/GrowthPlans/GrowthPlan/components/EditGrowthPlan/ActionItems/ActionItem'
import { ConnectMetricPopup } from '@src/features/GrowthPlans/GrowthPlan/components/MetricsConnector/ConnectMetricPopup'
import { ContentTypeOption } from '@src/interfaces/selectors'
import { CultureValueInterface } from '@src/interfaces/cultureValues'
import { CompetencyMatrixSkill } from '@src/interfaces/roles'
import { EmployeeInterface } from '@src/interfaces/employees'
import { getRequirements } from '@src/features/GrowthPlans/GrowthPlan/components/EditGrowthPlan/ActionItems/Requirements'
import { useGetPerformanceSummaryForGrowthPlan } from '@src/api/growthPlans'
import { useIsGrowthPlansEnabled } from '@src/features/GrowthPlans/hooks/useIsGrowthPlansEnabled'

const actionsFieldName = 'actions'
const actionDescriptionFieldName = 'description'
const makeActionDescriptionFieldName = (index: number) =>
  `${actionsFieldName}.${index}.${actionDescriptionFieldName}`

interface Props {
  employee: EmployeeInterface
}

export const ActionItemsWidget = ({ employee }: Props) => {
  const { values } = useLapeContext<GrowthPlanInterface>()
  const isGrowthPlansEnabled = useIsGrowthPlansEnabled()
  const { data: performanceSummary = null } = useGetPerformanceSummaryForGrowthPlan(
    {
      employee_id: employee.id,
      target_seniority_id: values.target_seniority.id,
      target_specialisation_id: values.target_specialisation.id,
    },
    isGrowthPlansEnabled,
  )

  const [shouldScrollToBottom, setShouldScrollToBottom] = useState<boolean>(false)
  const buttonRef = useRef<HTMLButtonElement | null>(null)
  const [isConnectMetricPopupOpen, connectMetricPopupToggler] = useToggle()
  const [selectedActionIndex, setSelectedActionIndex] = useState<number>()

  useEffect(() => {
    if (!shouldScrollToBottom || !buttonRef.current) {
      return
    }

    buttonRef.current.scrollIntoView({ behavior: 'smooth' })
    setShouldScrollToBottom(false)
  }, [shouldScrollToBottom, buttonRef.current])

  const makeDeleteHandler = (index: number) => () => {
    values.actions.splice(index, 1)

    if (index === selectedActionIndex) {
      setSelectedActionIndex(undefined)
    }
  }

  const handleAdd = () => {
    values.actions.push({ description: '' } as GrowthPlanActionInterface)
    setShouldScrollToBottom(true)
  }

  const makeConnectMetricPopupHandler = (index: number) => () => {
    setSelectedActionIndex(index)
    connectMetricPopupToggler.on()
  }

  const handleConnectMetric = (
    contentOption: ContentTypeOption<'skillset' | 'companyvalue'>,
    metric: CompetencyMatrixSkill | CultureValueInterface,
  ) => {
    if (selectedActionIndex === undefined) {
      return
    }

    values.actions[selectedActionIndex].object_id = metric.id
    values.actions[selectedActionIndex].content_type = {
      id: Number(contentOption.id),
      model: contentOption.name,
    } as GrowthPlanActionContentTypeInterface
    values.actions[selectedActionIndex].content_object = {
      id: metric.id,
      name: metric.name,
    } as GrowthPlanActionContentObjectInterface

    connectMetricPopupToggler.off()
  }

  const makeDisconnectMetricHandler = (index: number) => () => {
    values.actions[index].object_id = null
    values.actions[index].content_type = null
    values.actions[index].content_object = null

    connectMetricPopupToggler.off()
  }

  const canAddActionItem =
    values.actions.length === 0 ||
    values.actions.every(({ description }) => !!description)
  const requirementsTitle = `Requirements for ${values.target_seniority.name} level`

  return (
    <Widget>
      <Item style={transparentThemeBackgroundOverrides}>
        <Item.Avatar>
          <Avatar useIcon="Questionnaire" />
        </Item.Avatar>
        <Item.Content>
          <Item.Title>Growth plan actions</Item.Title>
          <Item.Description>
            To-do items for employee to successfully complete growth plan
          </Item.Description>
        </Item.Content>
      </Item>
      <VStack p="s-16" space="s-16" data-name="actions" use="li">
        {values.actions.map((action, index) => (
          <ActionItem
            key={action.id ?? index}
            action={action}
            name={makeActionDescriptionFieldName(index)}
            requirements={getRequirements(action, performanceSummary)}
            requirementsTitle={requirementsTitle}
            onDelete={makeDeleteHandler(index)}
            onConnectMetric={makeConnectMetricPopupHandler(index)}
            onDisconnectMetric={makeDisconnectMetricHandler(index)}
          />
        ))}
        <ActionButton
          useIcon="Plus"
          disabled={!canAddActionItem}
          onClick={handleAdd}
          ref={buttonRef}
        >
          Add to-do
        </ActionButton>
      </VStack>
      <ConnectMetricPopup
        growthPlan={values}
        action={
          selectedActionIndex !== undefined ? values.actions[selectedActionIndex] : null
        }
        isOpen={isConnectMetricPopupOpen}
        onClose={connectMetricPopupToggler.off}
        onConnect={handleConnectMetric}
      />
    </Widget>
  )
}
