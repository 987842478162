import { ReviewSummaryInterface } from '@src/interfaces/performance'
import { useGetGoals } from '@src/api/goals'
import { FilterByInterface } from '@src/interfaces/data'
import { ContributorType } from '@src/interfaces/talent/performance'

interface Props {
  isFetching: boolean
  isIndividualGoalsVisible: boolean
  isTeamGoalsVisible: boolean
}

export const useGoalsVisibilityBySummary = (
  summary:
    | Pick<
        ReviewSummaryInterface,
        'team' | 'grade_label_mapping' | 'reviewed_employee_type'
      >
    | undefined,
  filters: FilterByInterface[],
): Props => {
  const { data, isFetching } = useGetGoals(
    { filters },
    !!filters.find(({ columnName }) => columnName === 'cycle__id'),
  )
  const isIndividualGoalsEnabled = summary?.reviewed_employee_type !== ContributorType.IC
  const isIndividualGoalsVisible = !!(typeof summary?.reviewed_employee_type !==
  'undefined'
    ? isIndividualGoalsEnabled
    : data && data.count > 0)
  const isTeamGoalsVisible = !!(typeof summary?.reviewed_employee_type !== 'undefined'
    ? !isIndividualGoalsEnabled
    : data && data.count === 0)

  return {
    isFetching,
    isIndividualGoalsVisible,
    isTeamGoalsVisible,
  }
}
