import React from 'react'
import { useSelector } from 'react-redux'
import {
  Avatar,
  DataPoint,
  Flex,
  Grid,
  Highlights,
  HStack,
  Tag,
  Token,
} from '@revolut/ui-kit'
import { SeniorityProgression } from '@src/features/GrowthPlans/GrowthPlan/components/SeniorityProgression'
import { formatDate } from '@src/utils/format'
import Table from '@components/TableV2/Table'
import { GrowthPlanInterface } from '@src/interfaces/growthPlans'
import { EmployeeInterface } from '@src/interfaces/employees'
import { useCanCreateGrowthPlans } from '@src/features/GrowthPlans/hooks/useIsGrowthPlansEnabled'
import { selectUser } from '@src/store/auth/selectors'
import {
  getGrowthPlanDecisionColor,
  growthPlanDecisionToLabelMap,
} from '@src/features/GrowthPlans/GrowthPlan/components/constants'
import { growthPlanModel } from '@src/features/GrowthPlans/GrowthPlan/models/growthPlanModel'

interface Props {
  employee: EmployeeInterface
  growthPlan: GrowthPlanInterface
  prefix?: React.ReactNode
  actions?: React.ReactNode
}

export const GrowthPlanHeader = ({ employee, growthPlan, prefix, actions }: Props) => {
  const canCreateGrowthPlans = useCanCreateGrowthPlans(employee)
  const currentUser = useSelector(selectUser)

  return (
    <Grid columns="auto 1fr">
      <HStack space="s-32">
        {prefix && prefix}
        <Avatar useIcon="Flag" size={48} />
        <Highlights>
          <DataPoint>
            <DataPoint.Value>
              <Flex gap="s-8" alignItems="center">
                {growthPlan.target_specialisation.name}
                <Tag color={Token.color.grey50_20}>
                  <SeniorityProgression
                    values={[
                      growthPlan.current_seniority.name,
                      growthPlan.target_seniority.name,
                    ]}
                  />
                </Tag>
              </Flex>
            </DataPoint.Value>
            <DataPoint.Label>Target role</DataPoint.Label>
          </DataPoint>
          <DataPoint>
            <DataPoint.Value>{formatDate(growthPlan.deadline)}</DataPoint.Value>
            <DataPoint.Label>Timeline</DataPoint.Label>
          </DataPoint>
          {growthPlan.decision && !growthPlanModel.isOngoing(growthPlan) && (
            <DataPoint>
              <DataPoint.Value color={getGrowthPlanDecisionColor(growthPlan)}>
                {growthPlanDecisionToLabelMap[growthPlan.decision]}
              </DataPoint.Value>
              <DataPoint.Label>Status</DataPoint.Label>
            </DataPoint>
          )}
        </Highlights>
      </HStack>
      {!!actions && canCreateGrowthPlans && currentUser.id !== employee.id && (
        <Table.Widget.MoreBar maxCount={2} style={{ minWidth: 50 }}>
          {actions}
        </Table.Widget.MoreBar>
      )}
    </Grid>
  )
}
