import React from 'react'
import {
  Box,
  Button,
  Copyable,
  Text,
  HStack,
  StatusPopup,
  Token,
  useStatusPopup,
  Spacer,
} from '@revolut/ui-kit'
import { ParsableError, parseError } from './parseError'
import { ActionErrorTitle } from './components/ActionErrorTitle'
import { getMessageFromApiError } from '@src/store/notifications/actions'
import NewResponsiveCodeEditor from '@src/components/Inputs/NewCodeEditor/NewResponsiveCodeEditor'

interface UseErrorPopupShowParams {
  error: ParsableError
  /** Fallback title in case of an unknown unhandled error. Do not use `Something went wrong`, use user friendly message instead, e.g. `Could not archive team` */
  fallbackTitle: string
  fallbackDescription?: string
}

export const useErrorPopup = () => {
  const statusPopup = useStatusPopup()

  return {
    show: ({ error, fallbackTitle, fallbackDescription }: UseErrorPopupShowParams) => {
      const parsedError = parseError(error)
      const apiDescription = getMessageFromApiError(error)
      const requestId = error?.response?.headers['request-id']
      let description = apiDescription
      let technicalMessage: null | string = null

      if (parsedError.subType === 'be_error' || (!description && error?.response?.data)) {
        technicalMessage = `${requestId ? `${requestId}\n` : ''}${JSON.stringify(
          error?.response?.data,
          null,
          2,
        )}`
        description = null
      }
      if (!technicalMessage && fallbackDescription) {
        description = fallbackDescription
      }

      statusPopup.show(
        <StatusPopup variant="error">
          <StatusPopup.Title>
            <ActionErrorTitle error={parsedError} fallbackTitle={fallbackTitle} />
          </StatusPopup.Title>
          {description ? (
            <StatusPopup.Description>{description}</StatusPopup.Description>
          ) : technicalMessage ? (
            <StatusPopup.Description>
              <ErrorMessage
                displayMessage={technicalMessage}
                copyMessage={{
                  status: error?.response?.status,
                  message: error?.message,
                  data: error?.response?.data,
                  method: error?.config?.method?.toUpperCase(),
                  url: error?.config.url,
                  requestId,
                }}
              />
            </StatusPopup.Description>
          ) : null}
          <StatusPopup.Actions>
            <Button onClick={statusPopup.hide} variant="secondary">
              Close
            </Button>
          </StatusPopup.Actions>
        </StatusPopup>,
      )
    },
    hide: statusPopup.hide,
  }
}

interface ErrorMessageInterface {
  /** Message shown to the user in the popup */
  displayMessage: string
  /** Object with more details that will be copied to user clipboard */
  copyMessage: {
    status?: number
    message?: string
    data?: unknown
    method?: string
    url?: string
    requestId?: string
  }
}

export const ErrorMessage = ({ displayMessage, copyMessage }: ErrorMessageInterface) => {
  return (
    <Box
      backgroundColor={Token.color.groupedBackground}
      radius={Token.radius.widget}
      p="s-16"
    >
      <HStack mb="s-8">
        <Text>Error message</Text>
        <Spacer />
        <Copyable
          value={`${copyMessage.method} ${copyMessage.url}\n${copyMessage.status} ${
            copyMessage.message
          }\nRequest ID: ${copyMessage.requestId || '-'}\n${JSON.stringify(
            copyMessage.data,
            null,
            2,
          )}`}
          labelButtonCopy="Copy"
          style={{ width: 'auto' }}
        />
      </HStack>
      <Text textAlign="left">
        <NewResponsiveCodeEditor
          readonly
          language="json"
          /** Code editor and popup doesn't support dynamic heights. But expected `displayMessage` is stringified JSON, so we can count the number of lines */
          height={Math.min((displayMessage?.split('\n').length || 1) * 18, 270)}
          value={displayMessage}
          transparent
          customOptions={{
            lineNumbers: 'off',
            scrollbar: { horizontal: 'hidden', vertical: 'hidden' },
            folding: false,
          }}
        />
      </Text>
    </Box>
  )
}
