import {
  ActionButton,
  Avatar,
  Button,
  Grid,
  Group,
  HStack,
  Input,
  Item,
  Radio,
  Skeleton,
  useToggle,
  VStack,
  Widget,
} from '@revolut/ui-kit'
import { goBack } from '@src/actions/RouterActions'
import { useGetGrades, useUpdateGrade } from '@src/api/performanceSettings'
import { PageActions } from '@src/components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { Grade, PerformanceSettingsInterface } from '@src/interfaces/settings'
import { getMessageFromApiError } from '@src/store/notifications/actions'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { connect } from 'lape'
import { isNumber } from 'lodash'
import React, { useEffect, useState } from 'react'
import { parsePercentage } from './utils/parsePercentage'
import { GradesPreviewSidebar } from './components/GradesPreviewSidebar'
import { DeliverablesSettingsWidget } from './components/DeliverablesSettingWidget'

export const ManageEvaluationCriteria = connect(() => {
  const showStatusPopup = useShowStatusPopup()
  const [previewSidebarOpen, setPreviewSidebarOpen] = useToggle()
  const { values, initialValues, submit, isSubmitting } =
    useLapeContext<PerformanceSettingsInterface>()
  const { data: { results: currentGrades = [] } = {}, isLoading: isGradesLoading } =
    useGetGrades()
  const { mutateAsync: updateGrade, isLoading: isUpdatingGrades } = useUpdateGrade()

  const [updatedGrades, setUpdatedGrades] = useState<
    (Pick<Grade, 'id' | 'goal_performance_percent' | 'label'> & {
      inputPercent: string | null
      error?: string
    })[]
  >([])

  useEffect(() => {
    if (!isGradesLoading && currentGrades) {
      setUpdatedGrades(
        currentGrades.map(v => ({
          ...v,
          inputPercent:
            v.goal_performance_percent === 'None' || v.goal_performance_percent === null
              ? null
              : `${v.goal_performance_percent}%`,
        })),
      )
    }
  }, [currentGrades])

  const setNewLabel = (id: number, label: string) => {
    setUpdatedGrades(prev =>
      prev.map(gradeItem => (gradeItem.id === id ? { ...gradeItem, label } : gradeItem)),
    )
  }

  const setNewPercentage = (id: number, value: string) => {
    const validInput = /^\d*(\.|,)?\d*%?$/
    if (!validInput.test(value)) {
      return
    }

    setUpdatedGrades(prev => {
      return prev.map((gradeItem, i) => {
        const newValue = parsePercentage(value)
        const highterValue = prev.at(i + 1)?.goal_performance_percent
        const lowerValue = prev.at(i - 1)?.goal_performance_percent
        const hasError = () => {
          if (i < prev.length - 1) {
            if (newValue === null) {
              return 'Invalid value'
            }
            if (isNumber(highterValue) && newValue > highterValue) {
              return 'Invalid value'
            }
            if (isNumber(lowerValue) && newValue < lowerValue) {
              return 'Invalid value'
            }
          }
          return undefined
        }
        return gradeItem.id === id
          ? {
              ...gradeItem,
              goal_performance_percent: newValue,
              inputPercent: value,
              error: hasError(),
            }
          : gradeItem
      })
    })
  }

  const updateGrades = () => {
    return Promise.all(
      updatedGrades.map(grade => {
        if (grade.error) {
          throw new Error('Provide correct grades percentage')
        }
        const currentGrade = currentGrades.find(({ id }) => id === grade.id)
        if (
          currentGrade &&
          (currentGrade.label !== grade.label ||
            currentGrade.goal_performance_percent !== grade.goal_performance_percent)
        ) {
          return updateGrade([
            grade.id,
            {
              goal_performance_percent: grade.goal_performance_percent,
              label: grade.label,
            },
          ])
        }
        return () => {}
      }),
    )
  }

  const submitAction = async () => {
    await updateGrades()
    submit()
  }

  return (
    <>
      <VStack gap="s-16">
        <Group>
          <Item>
            <Item.Content>
              <Item.Title>Performance Evaluation Criteria</Item.Title>
              <Item.Description>
                Choose how managers will assess employee performance: based on quarterly
                goals or generic deliverables.
              </Item.Description>
            </Item.Content>
          </Item>
          <HStack gap="s-8" px="s-16" pb="s-16">
            <Item
              use="label"
              height="100%"
              aria-pressed={!values.enable_segmented_deliverables_assessment}
              onClick={() => {
                values.enable_segmented_deliverables_assessment = false
              }}
            >
              <Item.Prefix>
                <Radio
                  checked={!values.enable_segmented_deliverables_assessment}
                  aria-labelledby="goals-title"
                  aria-describedby="goals-description"
                />
              </Item.Prefix>
              <Item.Content>
                <Item.Title id="goals-title">Goals for the cycle</Item.Title>
                <Item.Description id="goals-description">
                  Evaluate employees based on their individual or team goals set for this
                  cycle.
                </Item.Description>
              </Item.Content>
            </Item>
            <Item
              use="label"
              height="100%"
              aria-pressed={values.enable_segmented_deliverables_assessment}
              onClick={() => {
                values.enable_segmented_deliverables_assessment = true
              }}
            >
              <Item.Prefix>
                <Radio
                  checked={values.enable_segmented_deliverables_assessment}
                  aria-labelledby="deliverables-title"
                  aria-describedby="deliverables-description"
                />
              </Item.Prefix>
              <Item.Content>
                <Item.Title id="deliverables-title">Generic deliverables</Item.Title>
                <Item.Description id="deliverables-description">
                  Assess performance using standardized criteria: Speed, Complexity, and
                  Quality.
                </Item.Description>
              </Item.Content>
            </Item>
          </HStack>
        </Group>
        {values.enable_segmented_deliverables_assessment && (
          <DeliverablesSettingsWidget />
        )}
        <Widget>
          <Item>
            <Item.Avatar>
              <Avatar useIcon="Trophy" />
            </Item.Avatar>
            <Item.Content>
              <Item.Title>Grades</Item.Title>
              <Item.Description>
                The calibration logic sets a benchmark for expected results by defining
                the percentage of employees you expect to achieve each performance grade.
              </Item.Description>
            </Item.Content>
            <Item.Side>
              <ActionButton useIcon="EyeShow" onClick={setPreviewSidebarOpen.switch}>
                Preview
              </ActionButton>
            </Item.Side>
          </Item>
          <VStack gap="s-8" pl="s-64" pr="s-16" pb="s-16">
            {isGradesLoading ? (
              <>
                <Skeleton height={50} radius="widget" />
                <Skeleton height={50} radius="widget" />
                <Skeleton height={50} radius="widget" />
                <Skeleton height={50} radius="widget" />
                <Skeleton height={50} radius="widget" />
              </>
            ) : (
              updatedGrades.map((grade, i) => (
                <Grid key={grade.id} columns="1fr 100px" gap="s-8">
                  <Input
                    value={grade.label}
                    label={`Grade ${i + 1}`}
                    onChange={event => {
                      setNewLabel(grade.id, event.currentTarget.value)
                    }}
                  />
                  <Input
                    invalid={!!grade.error}
                    errorMessage={grade.error}
                    onChange={event =>
                      setNewPercentage(grade.id, event.currentTarget.value)
                    }
                    label={
                      i === 0
                        ? 'Below'
                        : i === updatedGrades.length - 1
                        ? 'Above'
                        : 'Up to'
                    }
                    value={
                      grade.inputPercent === null
                        ? `>${updatedGrades[i ? i - 1 : i].inputPercent}`
                        : grade.inputPercent
                    }
                    disabled={i === updatedGrades.length - 1}
                  />
                </Grid>
              ))
            )}
          </VStack>
        </Widget>
        <PageActions>
          <Button
            variant="secondary"
            onClick={() => {
              values.enable_segmented_deliverables_assessment =
                initialValues.enable_segmented_deliverables_assessment || false // satisfy TS
              goBack()
            }}
          >
            Cancel
          </Button>
          <NewSaveButtonWithPopup
            useValidator
            pending={isUpdatingGrades || isSubmitting}
            onClick={submitAction}
            onSubmitError={e => {
              showStatusPopup({
                status: 'error',
                title: "Couldn't udate evaluation criteria",
                description: getMessageFromApiError(e),
              })
            }}
            hideWhenNoChanges={false}
            onAfterSubmit={() => {
              goBack()
            }}
          >
            Save
          </NewSaveButtonWithPopup>
        </PageActions>
      </VStack>
      <GradesPreviewSidebar
        isOpen={previewSidebarOpen}
        onClose={setPreviewSidebarOpen.off}
        grades={updatedGrades}
      />
    </>
  )
})
