import React from 'react'
import { VStack } from '@revolut/ui-kit'
import { GeneralInfoWidget } from '@src/features/GrowthPlans/GrowthPlan/components/EditGrowthPlan/GeneralInfoWidget'
import { EmployeeInterface } from '@src/interfaces/employees'
import { ActionItemsWidget } from '@src/features/GrowthPlans/GrowthPlan/components/EditGrowthPlan/ActionItems/ActionItemsWidget'

interface Props {
  employee: EmployeeInterface
}

export const EditGrowthPlanFormContent = ({ employee }: Props) => {
  return (
    <VStack space="s-16">
      <GeneralInfoWidget employee={employee} />
      <ActionItemsWidget employee={employee} />
    </VStack>
  )
}
