import React from 'react'
import { Caption, Flex, Text, Token } from '@revolut/ui-kit'
import { RadioOption } from '@components/Inputs/NewRadioButtons/NewRadioButtons'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { cycleModel } from '@src/features/ReviewCycle/PerformanceReviewCycle/models/CycleModel'
import { OptionInterface } from '@src/interfaces/selectors'

interface Props {
  title: string
  description: string
  isDisabled: boolean
}

const OptionLabel = ({ title, description, isDisabled }: Props) => (
  <Flex flexDirection="column" style={{ cursor: isDisabled ? 'not-allowed' : 'pointer' }}>
    <Text>{title}</Text>
    <Caption variant="caption" color={Token.color.greyTone50}>
      {description}
    </Caption>
  </Flex>
)

export enum CycleMode {
  Scheduled = 'Scheduled',
  Manual = 'Manual',
}

const makeScheduledOption = (
  cycle: ReviewCyclesInterface,
): RadioOption<OptionInterface<CycleMode>> => {
  const isDisabled = cycleModel.isTest(cycle)

  return {
    value: {
      name: CycleMode.Scheduled,
      id: CycleMode.Scheduled,
    },
    label: (
      <OptionLabel
        title="Schedule"
        description="Cycle stages change on fixed dates"
        isDisabled={isDisabled}
      />
    ),
    disabled: isDisabled,
    tooltip: isDisabled ? 'Test cycle cannot be converted to scheduled' : undefined,
  }
}

const makeManualOption = (
  cycle: ReviewCyclesInterface,
): RadioOption<OptionInterface<CycleMode>> => {
  const isDisabled = cycleModel.isClosed(cycle)

  return {
    value: {
      name: CycleMode.Manual,
      id: CycleMode.Manual,
    },
    label: (
      <OptionLabel
        title="Manual"
        description="Move to the next stage manually"
        isDisabled={isDisabled}
      />
    ),
    disabled: isDisabled,
    tooltip: isDisabled ? 'Closed cycle cannot be converted to manual' : undefined,
  }
}

export const makeCycleModeOptions = (
  cycle: ReviewCyclesInterface,
): RadioOption<OptionInterface<CycleMode>>[] => [
  makeScheduledOption(cycle),
  makeManualOption(cycle),
]
