import {
  ActionButton,
  ActionButtonSkeleton,
  Box,
  chain,
  Text,
  TextSkeleton,
  VStack,
} from '@revolut/ui-kit'
import pluralize from 'pluralize'
import React from 'react'
import { useGoalsStats } from '@src/features/ReviewCycle/PerformanceReviewCycle/CycleStages/Timeline/StageWidget/Sidebar/Goals/useGoalsStats'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { GoalsOwner } from '@src/features/ReviewCycle/PerformanceReviewCycle/models/GoalsModel'
import {
  goalsOwnerToEntityNameMap,
  makeMissingEntitiesText,
} from '@src/features/ReviewCycle/PerformanceReviewCycle/CycleStages/Timeline/StageWidget/constants'
import { useNavigation } from '@src/features/ReviewCycle/PerformanceReviewCycle/hooks/useNavigation'
import capitalize from 'lodash/capitalize'
import { getColorForPortion } from '@src/features/ReviewCycle/PerformanceReviewCycle/components/Progress/styles'
import { formatPrecisePercentage } from '@src/features/ReviewCycle/PerformanceReviewCycle/formatters/formatPrecisePercentage'

interface Props {
  cycle: ReviewCyclesInterface
  goalsOwner: GoalsOwner
}

export const GoalsOverview = ({ cycle, goalsOwner }: Props) => {
  const { count, isLoading } = useGoalsStats(cycle, goalsOwner)
  const { toPerformanceDepartmentGoals, toPerformanceTeamGoals } = useNavigation()

  const { totalCount, withGoalsCount } = count
  const withGoalsPortion = withGoalsCount / totalCount

  const color = getColorForPortion(withGoalsPortion)

  const noEntitiesDescription = makeMissingEntitiesText(goalsOwner)
  const description = chain(
    pluralize(goalsOwnerToEntityNameMap[goalsOwner], withGoalsCount, true),
    <Text color={color}>{formatPrecisePercentage(withGoalsPortion)}</Text>,
  )

  const handleOverviewGoalsClick =
    goalsOwner === GoalsOwner.Team
      ? toPerformanceTeamGoals(cycle)
      : toPerformanceDepartmentGoals(cycle)

  if (isLoading) {
    return (
      <VStack space="s-16">
        <TextSkeleton width={100} />
        <ActionButtonSkeleton width={200} />
      </VStack>
    )
  }

  if (totalCount === 0) {
    return <Text>{noEntitiesDescription}</Text>
  }

  return (
    <VStack space="s-16">
      <Box>{description}</Box>
      <ActionButton useIcon="LinkExternal" onClick={handleOverviewGoalsClick}>
        {`${capitalize(goalsOwnerToEntityNameMap[goalsOwner])} goals overview`}
      </ActionButton>
    </VStack>
  )
}
