import React from 'react'

import { getEngagementResultsTableV2Requests } from '@src/api/engagement'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'
import { useTable } from '@src/components/TableV2/hooks'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import { useIsNewTable } from '@src/components/TableV2/hooks'
import {
  engagementResultsV2AverageScoreColumn,
  engagementResultsV2QuestionCategoryNameColumn,
  engagementResultsV2QuestionNameColumn,
  engagementResultsV2QuestionTypeColumn,
} from '@src/constants/columns/engagementResultsV2'
import { TableNames } from '@src/constants/table'
import { EngagementResultV2Interface } from '@src/interfaces/engagement'
import { ResultsInterface, useApplySurveyResultsFilters } from './common'

const row = {
  cells: [
    { ...engagementResultsV2QuestionNameColumn, width: 350 },
    { ...engagementResultsV2QuestionCategoryNameColumn, width: 100 },
    { ...engagementResultsV2QuestionTypeColumn, width: 120 },
    { ...engagementResultsV2AverageScoreColumn, width: 95 },
  ],
}

export const ResultQuestionsTable = ({
  viewMode,
  surveyId,
  isLoading,
  timelineFilter,
  scopeFilters,
}: ResultsInterface) => {
  const isNewTable = useIsNewTable()
  const enableTableRequests = !isLoading && viewMode === 'table'

  const table = useTable<EngagementResultV2Interface>(
    getEngagementResultsTableV2Requests(surveyId, 'questions'),
    undefined,
    undefined,
    { disable: !enableTableRequests, disableOnEmptyFilters: false },
  )
  useApplySurveyResultsFilters({
    enabled: enableTableRequests,
    table,
    timelineFilter,
    scopeFilters,
  })

  return (
    <>
      <AdjustableTable
        name={TableNames.EngagementSurveysResultQuestions}
        row={row}
        emptyState={<EmptyTableRaw title="Results were not found" />}
        hideCount={isNewTable}
        useWindowScroll
        {...table}
      />
    </>
  )
}
