import { useMemo } from 'react'
import { Statuses } from '@src/interfaces'
import { BaseOption } from '@src/interfaces/selectors'
import { EntityTypes } from '@src/constants/api'
import { useGetGoalContentTypeFilter } from '@src/features/Goals/useGoalFilters'

interface UseInitialFiltersParams {
  cycle?: BaseOption
  object_id?: number | null
  is_company?: boolean
  content_type_model?: EntityTypes | null
}

export const useInitialFilters = ({
  object_id,
  cycle,
  is_company,
  content_type_model,
}: UseInitialFiltersParams) => {
  const { filter: contentTypeFilter } = useGetGoalContentTypeFilter(
    content_type_model || undefined,
  )
  const initialFilters = useMemo(() => {
    const computedFilters = is_company
      ? [
          {
            filters: [{ name: `True`, id: 'True' }],
            columnName: 'is_company',
            nonResettable: true,
            nonInheritable: true,
          },
          {
            filters: [{ name: `False`, id: 'False' }],
            columnName: 'is_top_level',
            nonResettable: true,
            nonInheritable: true,
          },
        ]
      : [
          contentTypeFilter,
          {
            columnName: 'object_id',
            filters: [{ id: String(object_id), name: String(object_id) }],
            nonResettable: true,
            nonInheritable: true,
          },
        ].filter(Boolean)

    return [
      ...(cycle?.id
        ? [
            {
              columnName: 'cycle__id',
              filters: [{ id: String(cycle.id), name: String(cycle.name) }],
              nonResettable: true,
            },
          ]
        : []),
      {
        columnName: 'approval_status',
        nonResettable: true,
        filters: [
          { id: Statuses.pending, name: Statuses.pending },
          { id: Statuses.approved, name: Statuses.approved },
          { id: Statuses.requires_changes, name: Statuses.requires_changes },
        ],
      },
      ...computedFilters,
    ]
  }, [content_type_model, object_id, cycle, is_company])

  return initialFilters
}
