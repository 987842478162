import React from 'react'
import { connect } from 'lape'
import { Box, Group, VStack } from '@revolut/ui-kit'
import LapeNewSwitch from '@components/Inputs/LapeFields/LapeNewSwitch'
import { enableScorecardsSwitcherProps } from '@src/features/ReviewCycle/PerformanceReviewCycle/CycleSettings/EditCycleSettings/constants'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { cycleModel } from '@src/features/ReviewCycle/PerformanceReviewCycle/models/CycleModel'
import { useGetPerformanceSettings } from '@src/api/performanceSettings'
import { settingsModel } from '@src/features/ReviewCycle/PerformanceReviewCycle/models/SettingsModel'
import { ReviewTypes } from '@src/features/ReviewCycle/PerformanceReviewCycle/CycleSettings/EditCycleSettings/ReviewTypes'
import { GeneralSettings } from '@src/features/ReviewCycle/PerformanceReviewCycle/CycleSettings/EditCycleSettings/GeneralSettings'

export const EditFormContent = connect(() => {
  const { values } = useLapeContext<ReviewCyclesInterface>()
  const { data: settings } = useGetPerformanceSettings()
  const isTest = cycleModel.isTest(values)
  const hasFunctionalManagement =
    !!settings && settingsModel.hasFunctionalManagement(settings)

  const canConfigureScorecardsGeneration = !cycleModel.isManual(values)

  return (
    <VStack space="s-16">
      <GeneralSettings isTest={isTest} />
      <ReviewTypes hasFunctionalManagement={hasFunctionalManagement} />
      <Group>
        <LapeNewSwitch
          name="enabled_scorecards_generations"
          itemTypeProps={{
            ...enableScorecardsSwitcherProps,
            tooltip: !canConfigureScorecardsGeneration ? (
              <Box p="s-2">Not editable for manual cycles</Box>
            ) : undefined,
          }}
          disabled={!canConfigureScorecardsGeneration}
        />
      </Group>
    </VStack>
  )
})
