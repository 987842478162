import React, { useState } from 'react'
import { EntityTypes, selectorKeys } from '@src/constants/api'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { goBack, navigateReplace } from '@src/actions/RouterActions'
import { useLapeContext } from '@src/features/Form/LapeForm'
import Form from '@src/features/Form/Form'
import { PageWrapper } from '@components/Page/Page'
import { bulkCascadeGoalsRequests } from '@src/api/goals'
import { GoalsInterface, GoalsOrganisationalUnit } from '@src/interfaces/goals'
import { useGetPerformanceSettings } from '@src/api/performanceSettings'
import {
  getDefaultBackRoute,
  useSubmitFlowHelpers,
} from '@src/pages/Forms/GoalForm/common/utils'
import { ApprovalStatuses } from '@src/interfaces/approvalFlow'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { FormObserverProvider } from '@src/pages/Forms/GoalForm/Form/Widgets/FormObserverProvider'
import { CascadeGoalFormPageBody } from '@src/pages/Forms/GoalForm/CascadeGoal/CascadeGoalFormPageBody'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { useGetCompany } from '@src/api/company'
import { useGetSelectors } from '@src/api/selectors'
import { useOrgUnitsProps } from './hooks/useOrgUnitsProps'
import { useGoalFormSubmit } from '../Form/useGoalFormSubmit'
import { Spinner } from '@revolut/ui-kit'
import { useLocation } from 'react-router-dom'
import { captureException } from '@sentry/core'
import { deleteGoal } from '@src/api/goals'
import { cleanGoalCache } from '../useGoalFormCache'

const General = () => {
  const [autoDeletePending, setAutoDeletePending] = useState(false)

  const { values, dirty } = useLapeContext<GoalsInterface>()
  const { data: performanceSettings } = useGetPerformanceSettings()
  const { data: company, isLoading: isLoadingCompany } = useGetCompany()
  const location = useLocation<{
    reviewCycleId?: string
    history: string[]
    isNew?: boolean
  }>()
  const { data: organisationalUnits, isLoading: isLoadingOrgUnits } =
    useGetSelectors<GoalsOrganisationalUnit>(selectorKeys.organisational_units)

  const { data: departmentsUnits, isLoading: isLoadingDepartmentsUnits } =
    useGetSelectors<GoalsOrganisationalUnit>(selectorKeys.departments_company)

  const isDraft = values.approval_status.id === ApprovalStatuses.Draft
  const isNew = !!location.state?.isNew

  const shouldAutoDelete = isDraft && isNew
  const shouldConfirmBack = isDraft && isNew && dirty
  const isLoadingUnits =
    isLoadingCompany || isLoadingOrgUnits || isLoadingDepartmentsUnits
  const { confirm, prompt, confirmationDialog } = useSubmitFlowHelpers()
  const { submit } = useGoalFormSubmit()

  const additionalContentProps = useOrgUnitsProps({
    company,
    organisationalUnits,
    departmentsUnits,
    contentTypeModel: values.content_type?.model,
  })

  const showStatusPopup = useShowStatusPopup()

  const backUrl = getDefaultBackRoute(values, false)

  const contentLabelByType = {
    [EntityTypes.department]: 'department',
    [EntityTypes.team]: 'team',
    [EntityTypes.teams]: 'team',
    [EntityTypes.employees]: 'employee',
    [EntityTypes.employee]: 'employee',
  }

  const entityTitle = values.content_type?.model
    ? contentLabelByType[values.content_type.model]
    : ''

  const onSubmitted = () => {
    if (values.approval_status.id === ApprovalStatuses.Draft) {
      goBack(backUrl)
    } else {
      navigateReplace(pathToUrl(ROUTES.FORMS.GOAL.PREVIEW, { id: values.id }))
    }
    showStatusPopup({
      title: 'Cascaded goal is created',
      status: 'success',
    })
  }

  const deleteAndGoBack = async () => {
    setAutoDeletePending(true)
    await deleteGoal(values.id).catch(captureException)
    setAutoDeletePending(false)
    cleanGoalCache(values.id)
    goBack(backUrl)
  }

  const confirmBack = async () => {
    const confirmVariant = values.name ? confirm : prompt

    const confirmed = await confirmVariant({
      yesMessage: 'Save as draft',
      noMessage: 'Delete goal',
      noBtnVariant: 'negative',
      variant: 'compact',
      label: 'You have unsaved changes',
      body: 'Do you want to save them or delete before proceeding?',
      promptLabel: 'Please set goal name before saving',
      commentRequired: true,
    })

    if (confirmed.status === 'canceled') {
      return
    }

    if (confirmed.status === 'confirmed') {
      if (confirmed.comment) {
        values.name = confirmed.comment
      }
      await submit('draft')
      goBack(backUrl)
    }

    if (confirmed.status === 'rejected') {
      deleteAndGoBack()
    }
  }

  const handleBack = () => {
    if (shouldConfirmBack) {
      confirmBack()
    } else if (shouldAutoDelete) {
      deleteAndGoBack()
    } else {
      goBack(backUrl)
    }
  }

  return performanceSettings ? (
    <PageWrapper>
      <PageHeader
        onClickBack={handleBack}
        variant="narrow"
        noWrap={false}
        title={`Cascade ${entityTitle} goal`}
        backUrl={backUrl}
        backButton={autoDeletePending ? <Spinner /> : undefined}
        withVerticalSpacing
      />
      <FormObserverProvider>
        <CascadeGoalFormPageBody
          isLoadingUnits={isLoadingUnits}
          onSubmitted={onSubmitted}
          {...additionalContentProps}
        />
      </FormObserverProvider>
      {confirmationDialog}
    </PageWrapper>
  ) : null
}

export const CascadeGoalForm = () => {
  return (
    <PageWrapper>
      <Form
        api={bulkCascadeGoalsRequests}
        fieldsToExclude={['kpis', 'roadmaps', 'dashboards']}
        refetchOnLocationChange
      >
        <General />
      </Form>
    </PageWrapper>
  )
}
