import { GrowthPlanInterface } from '@src/interfaces/growthPlans'
import { useGetGrowthPlan, useGetGrowthPlanRelation } from '@src/api/growthPlans'
import { useIsGrowthPlansEnabled } from '@src/features/GrowthPlans/hooks/useIsGrowthPlansEnabled'
import { Statuses } from '@src/interfaces'

interface Props {
  data?: GrowthPlanInterface
  isFetching: boolean
  refetch: VoidFunction
}

export const useGetEmployeeGrowthPlan = (employeeId: number): Props => {
  const isGrowthPlansEnabled = useIsGrowthPlansEnabled()
  const {
    data: relation,
    isFetching: isRelationFetching,
    refetch: refetchRelation,
  } = useGetGrowthPlanRelation(employeeId, isGrowthPlansEnabled)
  const {
    data,
    isFetching: isGrowthPlanFetching,
    refetch: refetchGrowtPlan,
  } = useGetGrowthPlan(
    relation?.current_growth_plan?.growth_plan?.id,
    isGrowthPlansEnabled &&
      relation?.current_growth_plan?.growth_plan?.status !== Statuses.draft,
  )

  const refetch = () => {
    refetchRelation()
    refetchGrowtPlan()
  }

  return {
    isFetching: isRelationFetching || isGrowthPlanFetching,
    data,
    refetch,
  }
}
