import React, { useEffect } from 'react'
import {
  Token,
  Flex,
  Button,
  VStack,
  InputGroup,
  Box,
  Widget,
  Item,
  Avatar,
} from '@revolut/ui-kit'
import { PageActions } from '@src/components/Page/PageActions'
import { PageBody } from '@src/components/Page/PageBody'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { CascadeGoalInterface, GoalsOrganisationalUnit } from '@src/interfaces/goals'
import { ApprovalStatuses } from '@src/interfaces/approvalFlow'
import { useFormObserver } from '@src/pages/Forms/GoalForm/Form/Widgets/FormObserverProvider'
import {
  cleanGoalCache,
  useGoalFormCache,
} from '@src/pages/Forms/GoalForm/useGoalFormCache'
import { selectorKeys } from '@src/constants/api'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { CycleInput } from '@src/components/Inputs/Filters/FilterSelect/CycleFilter/CycleInput'
import { CascadeGoalsTable } from './CascadeGoalsTable'
import { useBulkCascadeCreate } from '@src/api/goals'
import { useGetSelectors } from '@src/api/selectors'
import { ReviewCyclesSelectorInterface } from '@src/interfaces/reviewCycles'
import { CycleOption, SelectorType } from '@src/interfaces/selectors'
import { useGetEmployeeCyclesSelector } from '@src/features/Goals/common/useGetEmployeeCyclesSelector'
import { useErrorPopup } from '@src/features/Errors/useErrorPopup'

const CURRENT_CYCLE_OFFSET = '0'

interface Props {
  onSubmitted: () => void
  isLoadingUnits: boolean
  selectorKey: SelectorType
  defaultValue?: GoalsOrganisationalUnit
  orgUnitsSelectLabel: string
}

export const CascadeGoalFormPageBody = ({
  onSubmitted,
  isLoadingUnits,
  selectorKey,
  defaultValue,
  orgUnitsSelectLabel,
}: Props) => {
  const { mutateAsync: createCascadeGoals, isLoading: isSubmitting } =
    useBulkCascadeCreate()

  const goalForm = useLapeContext<CascadeGoalInterface>()
  const { values, dirty } = goalForm
  const { hasDirtyForms } = useFormObserver()
  const { cacheUpdated } = useGoalFormCache()
  const { cycleSelector, initialCycle } = useGetEmployeeCyclesSelector(
    values.content_type?.model === 'employees' ? values.content_object.id : undefined,
    true,
  )
  const { data: reviewCycles } = useGetSelectors<ReviewCyclesSelectorInterface>(
    selectorKeys.review_cycles,
  )

  const errorPopup = useErrorPopup()

  const isDraft = values.approval_status.id === ApprovalStatuses.Draft

  const isDirty = hasDirtyForms() || dirty

  const targetCycle = reviewCycles?.find(
    cycle => String(cycle.offset) === CURRENT_CYCLE_OFFSET,
  )

  const selectedCycle =
    values.content_type?.model === 'employees' ? initialCycle : targetCycle

  useEffect(() => {
    values.cycle = selectedCycle
      ? { id: selectedCycle.id, name: selectedCycle.name }
      : undefined
  }, [selectedCycle])

  const onSubmit = async () => {
    try {
      await createCascadeGoals(
        {
          owner: values.owner,
          content_type: values.content_type,
          object_id: values.object_id,
          parent_goals: values.parent_goals,
        },
        {},
      )
      onSubmitted()
      cleanGoalCache(values.id)
    } catch (error) {
      errorPopup.show({
        fallbackTitle: 'Failed to submit',
        error,
      })
    }
  }

  useEffect(() => {
    if (defaultValue && !values.organisational_units) {
      values.organisational_units = defaultValue
    }
  }, [defaultValue])

  const isGoalsTableVisible = values.organisational_units?.id && values.cycle

  return (
    <PageBody maxWidth={{ all: Token.breakpoint.lg, xxl: Token.breakpoint.xl }}>
      {cacheUpdated && (
        <VStack space="s-24" px="s-16">
          <Widget p="s-16">
            <Item>
              <Item.Avatar>
                <Avatar useIcon="Target" />
              </Item.Avatar>
              <Item.Content>
                <Item.Title>General info</Item.Title>
              </Item.Content>
            </Item>
            <InputGroup>
              <LapeRadioSelectInput
                selector={selectorKeys.employee}
                name="owner"
                label="Owner"
                required
              />
              <LapeRadioSelectInput
                loading={isLoadingUnits}
                name="organisational_units"
                selector={selectorKey}
                label={orgUnitsSelectLabel}
              />
              <CycleInput
                value={values.cycle}
                selector={
                  values.content_type?.model === 'employees'
                    ? cycleSelector
                    : selectorKeys.review_cycles
                }
                label="Review cycle"
                onChange={(cycle: CycleOption) => {
                  values.cycle = cycle
                }}
              />
            </InputGroup>
            <Box mt="s-8">
              {isGoalsTableVisible && (
                <CascadeGoalsTable
                  cycle={values.cycle}
                  content_type_model={values.organisational_units.content_type_model}
                  is_company={values.organisational_units.is_company}
                  object_id={values.organisational_units.id}
                />
              )}
            </Box>
          </Widget>
        </VStack>
      )}

      <PageActions alignSelf="center" aria-label="page actions" mt="s-24">
        <Flex justifyContent="center" maxWidth="340px" alignSelf="center" gap="s-8">
          {isDirty && (
            <Button
              disabled={!values.parent_goals?.length}
              onClick={onSubmit}
              pending={isSubmitting}
              elevated
            >
              {isDraft ? 'Submit' : 'Save changes'}
            </Button>
          )}
        </Flex>
      </PageActions>
    </PageBody>
  )
}
